import {
  IonContent,
  IonPage,
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonButton,
  IonIcon,
  IonText,
  IonAlert,
} from "@ionic/react";
import { Input } from "../../ui/common/Input";
import React, { useState } from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import { atOutline } from "ionicons/icons";
import styled from "styled-components";

import { Link as ReactLink } from "react-router-dom";
import { Auth } from "../../api";
import { validateEmail } from "../../utils/validation";
import useWarningToast from "../../hooks/toasts/useWarningToast";
import useDangerToast from "../../hooks/toasts/useDangerToast";
import usePrimaryToast from "../../hooks/toasts/usePrimaryToast";
import axios from "axios";
import { useTranslation } from "react-i18next";
import TranslationBanners from "../../components/TranslationBanners/TranslationBanners";
import Logo from "../../images/logo.png";

const Button = styled(IonButton)`
  --background: #2196f3;
`;

const Link = styled(ReactLink)`
  cursor: pointer;
  color: #2196f3;
  text-decoration: none;
`;

const StyledIonRow = styled(IonRow)`
  flex-direction: column;
  gap: 30px;
  align-items: center;
`;

const StyledImage = styled.img`
  margin-top: 180px;
`;

const StyledIonCol = styled(IonCol)`
  gap: 20;
  display: flex;
  flex-direction: column;
`;

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [isError, setIserror] = useState<boolean>(false);
  const primaryToast = usePrimaryToast();
  const warningToast = useWarningToast();
  const dangerToast = useDangerToast();
  const { t } = useTranslation();
  const invalidEmail = t("validation.invalid-email");
  const emailSent = t("toast.email-sent");
  const emailNotFound = t("toast.email-not-found");
  const emailServerError = t("toast.email-server-error");
  const backToLogin = t("auth.back-to-login");
  const submit = t("common.submit");
  const emailTranslation = t("auth.email");

  const handleSendEmail = async (e: any) => {
    e.preventDefault();

    const isEmailValid = await validateEmail(email);
    if (!isEmailValid) {
      return warningToast(invalidEmail, 4000);
    }
    try {
      await Auth.forgotPassword(email);
      primaryToast(emailSent);
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          return dangerToast(emailNotFound, 4000);
        }
        dangerToast(emailServerError, 4000);
      }
    }
  };
  return (
    <IonPage>
      <IonContent className="ion-text-center">
        <div className="ion-padding">
          <TranslationBanners />
        </div>
        <IonGrid className="ion-no-padding">
          <StyledIonRow className="ion-justify-content-center">
            <StyledImage src={Logo} alt="logo" />

            <IonCol size="12" sizeMd="6" sizeXl="4">
              <IonCard>
                <form onSubmit={async (e) => await handleSendEmail(e)}>
                  <IonCardContent>
                    <IonGrid>
                      <IonRow className="ion-justify-content-center">
                        <IonCol>
                          <IonAlert
                            isOpen={isError}
                            onDidDismiss={() => setIserror(false)}
                            cssClass="my-custom-class"
                            header={"Error!"}
                            message={"Something went wrong. Please try again."}
                            buttons={["Dismiss"]}
                          />
                        </IonCol>
                      </IonRow>
                      <IonRow className="ion-justify-content-center">
                        <IonCol size="12" className="ion-margin-bottom">
                          <IonItem fill="outline">
                            <IonLabel position="floating">
                              {emailTranslation}
                            </IonLabel>
                            <IonIcon slot="start" icon={atOutline} />
                            <Input
                              type="email"
                              value={email}
                              onIonChange={(e) => setEmail(e.detail.value!)}
                              onKeyPress={async (e) => {
                                if (e.key === "Enter") {
                                  await handleSendEmail(e);
                                }
                              }}
                            ></Input>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow className="ion-justify-content-center">
                        <StyledIonCol>
                          <IonText style={{ textAlign: "left" }}>
                            <Link to="/login">{backToLogin}</Link>
                          </IonText>
                          <Button type="submit" expand="block">
                            {submit}
                          </Button>
                        </StyledIonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </form>
              </IonCard>
            </IonCol>
          </StyledIonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
