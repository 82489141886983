import { Component } from "react";
import { NETWORK_ERROR } from "../../utils/api-message";
import { IonAlert } from "@ionic/react";
import ErrorPage from "../ErrorPage/ErrorPage";

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
  message: string;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, message: "" };
  }

  static getDerivedStateFromError(error: any) {
    if (error && error?.message === NETWORK_ERROR) {
      return { hasError: true, message: NETWORK_ERROR };
    }
    return { hasError: true, message: error.message };
  }

  render() {
    if (this.state.hasError && this.state.message === NETWORK_ERROR) {
      return <ErrorPage />;
    }

    return (
      <>
        <IonAlert
          isOpen={this.state.hasError}
          onDidDismiss={() =>
            this.setState((prevState) => ({ ...prevState, hasError: false }))
          }
          cssClass="my-custom-class"
          header={"Something went wrong..."}
          message={this.state.message}
          buttons={["Dismiss"]}
        />
        {this.props.children}
      </>
    );
  }
}

export default ErrorBoundary;
