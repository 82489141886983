import {
  IonPage,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonIcon,
  IonButton,
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonList,
  IonRow,
  IonCol,
} from "@ionic/react";
import { Input } from "../../../ui/common/Input";
import { lockClosedOutline } from "ionicons/icons";
import { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { validatePassword } from "../../../utils/validation";
import { ChangePasswordData } from "../../../types/auth";
import { AxiosError } from "axios";

import useChangePassword from "../../../hooks/mutations/useChangePassword";
import useDangerToast from "../../../hooks/toasts/useDangerToast";
import useSuccessToast from "../../../hooks/toasts/useSuccessToast";
import PasswordRequirements from "../../PasswordRequirements/PasswordRequirements";

type ChangePasswordProps = {
  onDismiss: (data?: any | null, role?: string) => void;
};

const ChangePassword: React.FC<ChangePasswordProps> = (
  props: ChangePasswordProps
) => {
  const [oldPassword, setOldPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();

  const successToast = useSuccessToast();
  const dangerToast = useDangerToast();

  const changePassword = useChangePassword();

  const { t } = useTranslation();
  const passwordChangeSuccess = t("toast.password-change-success");
  const passwordChangeOldPasswordIncorrectError = t(
    "toast.old-password-incorrect"
  );

  const data = {
    oldPassword: oldPassword,
    password: newPassword,
    confirmPassword: confirmPassword,
  } as ChangePasswordData;

  const handleChangePassword = async () => {
    const { isValid, errorMessage } = await validatePassword(
      newPassword,
      confirmPassword
    );
    if (errorMessage) {
      const title = t("password-requirements.title");
      const errorMsg = t(errorMessage);
      return dangerToast(`${title} ${errorMsg}`, 5000);
    }

    if (isValid) {
      changePassword.mutate(data, {
        onSuccess() {
          successToast(passwordChangeSuccess);
          props.onDismiss();
        },
        onError(err) {
          if (err instanceof AxiosError) {
            if (err.response?.data.errors[0] === "PasswordMismatch") {
              dangerToast(passwordChangeOldPasswordIncorrectError);
            }
          }
        },
      });
    }
  };

  const cancelChanges = () => {
    const { onDismiss } = props;
    if (onDismiss !== undefined) {
      onDismiss(null, "cancel");
    }
  };

  return (
    <IonPage>
      <IonHeader mode="md" class="ion-no-border">
        <IonToolbar>
          <IonTitle
            style={{
              padding: "20px 0 20px 0px",
            }}
          >
            <IonTitle>{t("profile.change-password")}</IonTitle>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-no-padding ion-text-center ion-no-margin">
        <IonGrid>
          <Row>
            <IonLabel position="floating">{t("auth.oldPassword")}</IonLabel>
            <IonIcon slot="start" icon={lockClosedOutline} />
            <Input
              type="password"
              value={oldPassword}
              onIonChange={(e) => setOldPassword(e.detail.value!)}
            />
          </Row>
          <Row>
            <IonLabel position="floating">{t("profile.new-password")}</IonLabel>
            <IonIcon slot="start" icon={lockClosedOutline} />
            <Input
              type="password"
              value={newPassword}
              onIonChange={(e) => setNewPassword(e.detail.value!)}
            />
          </Row>
          <Row className="ion-no-margin">
            <IonLabel position="floating">
              {t("auth.confirm-password")}
            </IonLabel>
            <IonIcon slot="start" icon={lockClosedOutline} />
            <Input
              type="password"
              value={confirmPassword}
              onIonChange={(e) => setConfirmPassword(e.detail.value!)}
            />
          </Row>
          <IonList
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "20px 3px 0 0",
            }}
          >
            <IonButtons slot="end">
              <IonButton
                onClick={cancelChanges}
                class="custom-button"
                style={{
                  borderRadius: "5px",
                  border: "1px solid black",
                }}
              >
                {t("common.cancel")}
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton
                onClick={handleChangePassword}
                class="custom-button"
                style={{
                  backgroundColor: "#2196f3",
                  color: "white",
                  borderRadius: "5px",
                }}
              >
                {t("common.done")}
              </IonButton>
            </IonButtons>
          </IonList>
          <IonList className="ion-no-margin ion-no-padding">
            <PasswordRequirements password={newPassword ? newPassword : ""} />
          </IonList>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default ChangePassword;

const Row = (props: PropsWithChildren<{ className?: string }>) => {
  return (
    <IonRow className="ion-justify-content-center">
      <IonCol size="12" className={props.className ?? "ion-margin-bottom"}>
        <IonItem mode="md" fill="outline">
          {props.children}
        </IonItem>
      </IonCol>
    </IonRow>
  );
};
