import { Inventory } from "../../../types/inventory";
import { TableColumn } from "react-data-table-component";
import BaseTable from "../BaseTable/BaseTable";
import BaseTableHead from "../BaseTable/BaseTableHead";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { getSearchResult } from "../../../utils/filter";
import { CellWithLongerContentContainer } from "../../../ui/common/TableCell";
import Popover from "../../Popover/Popover";
import { ExportInventoryToExcel } from "../../ExportIndex/ExportInventoryToExcel";
import CellWithInfo from "../CellWithInfo";

const customStyle = {
  headCells: {
    style: {
      fontFamily: "Poppins",
      fontSize: "14px",
      color: "#6E7191",
    },
  },
  cells: {
    style: {
      fontFamily: "Poppins",
      fontSize: "14px",
      color: "#6E7191",
    },
  },
};

type InventoryTableProps = {
  data: Inventory[];
};

export default function InventoryTable(props: InventoryTableProps) {
  const { t } = useTranslation();
  const articleNumber = t("table.inventory.article-number");
  const articleNumberInfo = t("table.inventory.article-number-info");
  const description = t("table.inventory.description");
  const descriptionInfo = t("table.inventory.description-info");
  const gtinEan = t("table.inventory.gtin-ean");
  const gtinEanInfo = t("table.inventory.gtin-ean-info");
  const ic = t("table.inventory.ic");
  const icInfo = t("table.inventory.ic-info");
  const sc = t("table.inventory.sc");
  const scInfo = t("table.inventory.sc-info");
  const quantity = t("table.inventory.quantity");
  const availableQuantity = t("table.inventory.available-quantity");
  const quantityInfo = t("table.inventory.quantity-info");
  const availableQuantityInfo = t("table.inventory.available-quantity-info");
  const storageLocation = t("table.inventory.storage-location");
  const inventoryTitle = t("navigation.inventory");
  const [searchInput, setSearchInput] = useState<string>("");

  const searchResult = getSearchResult<Inventory>(props.data, searchInput);

  const columns: TableColumn<Inventory>[] = [
    {
      ...(window.innerWidth < 1483 ? { width: "170px" } : {}),
      selector: (row) => row.productId,
      name: (
        <CellWithInfo cellText={articleNumber} infoText={articleNumberInfo} />
      ),
      grow: 1,
      sortable: false,
    },
    {
      // width: "160px",
      name: <CellWithInfo cellText={description} infoText={descriptionInfo} />,
      selector: (row) => row.description,
      grow: 2,
      cell: (row) => (
        <CellWithLongerContentContainer>
          <Popover value={row.description}>{row.description}</Popover>
        </CellWithLongerContentContainer>
      ),
      // width: "350px",
      sortable: false,
    },
    {
      // width: "160px",
      name: <CellWithInfo cellText={gtinEan} infoText={gtinEanInfo} />,
      // width: "200px",
      selector: (row) => row.ean,
      sortable: false,
      grow: 1,
    },
    {
      // width: "160px",
      name: <CellWithInfo cellText={ic} infoText={icInfo} />,
      selector: (row) => row.eanic,
      grow: 1,
      // width: "200px",
      sortable: false,
    },
    {
      // width: "160px",
      name: <CellWithInfo cellText={sc} infoText={scInfo} />,
      selector: (row) => row.eanoc,
      grow: 1,
      // width: "200px",
      sortable: false,
    },
    {
      // width: "160px",
      name: <CellWithInfo cellText={quantity} infoText={quantityInfo} />,
      // width: "180px",
      grow: 1,
      selector: (row) => row.quantity,
      right: true,
      sortable: true,
    },

    {
      name: (
        <CellWithInfo
          cellText={availableQuantity}
          infoText={availableQuantityInfo}
        />
      ),
      grow: 1,
      selector: (row) => row.availableQuantity,
      right: true,
      sortable: true,
    },
    // {
    //   name: storageLocation,
    //   selector: (row) => row.storageLocation,
    //   sortable: false,
    // },
  ];
  return (
    <>
      <ExportInventoryToExcel inventories={props.data ?? []} />
      <BaseTableHead
        pageTitle={inventoryTitle}
        setSearchInput={setSearchInput}
      />
      <BaseTable
        data={searchResult}
        columns={columns}
        customStyles={customStyle}
      />
    </>
  );
}
