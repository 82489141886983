import { TableColumn } from "react-data-table-component";
import BaseTable from "../BaseTable";
import { UserOutputDTO } from "../../../types/users";
import { useState } from "react";
import { IonButton, IonIcon, useIonAlert, useIonModal } from "@ionic/react";
import { trashOutline, createOutline } from "ionicons/icons";
import useDeleteUser from "../../../hooks/mutations/useDeleteUser";
import useSuccessToast from "../../../hooks/toasts/useSuccessToast";
import useDangerToast from "../../../hooks/toasts/useDangerToast";
import { useTranslation } from "react-i18next";
import CreateOrEditUserModalContent, {
  TitleEnum,
} from "../../ModalsContents/CreateOrEditUser/CreateOrEditUser";
import { CellWithLongerContentContainer } from "../../../ui/common/TableCell";
import Popover from "../../Popover";

type UsersTableProps = {
  data: UserOutputDTO[];
};

export const customStyle = {
  headCells: {
    style: {
      fontFamily: "Poppins",
      fontSize: "14px",
      color: "#6E7191",
    },
  },
  cells: {
    style: {
      fontFamily: "Poppins",
      fontSize: "14px",
      color: "#6E7191",
    },
  },
};

export default function UsersTable(props: UsersTableProps) {
  const [user, setUser] = useState<UserOutputDTO>();
  const [presentAlert] = useIonAlert();
  const deleteUser = useDeleteUser();
  const successToast = useSuccessToast();
  const dangerToast = useDangerToast();
  const [present, dismiss] = useIonModal(CreateOrEditUserModalContent, {
    onDismiss: (data: Partial<UserOutputDTO>, role: "confirm" | "cancel") =>
      dismiss(data, role),
    title: TitleEnum.EditUser,
    initialUser: user,
  });
  const { t } = useTranslation();

  const columns: TableColumn<UserOutputDTO>[] = [
    {
      name: t(`table.user.username`),
      selector: (row) => row.username ?? "",
      cell: (row) => (
        <CellWithLongerContentContainer width="200px">
          <Popover value={row?.username ?? ""}>{row?.username ?? ""}</Popover>
        </CellWithLongerContentContainer>
      ),
      width: "200px",
    },
    {
      name: t(`table.user.email`),
      selector: (row) => row.email ?? "",
      cell: (row) => (
        <div>
          <Popover
            // textOverflow={true}
            value={row?.email ?? ""}
          >
            {row?.email ?? ""}
          </Popover>
        </div>
      ),
      width: "220px",
    },
    {
      name: t(`table.user.firstname`),
      selector: (row) => row.firstName ?? "",
      cell: (row) => (
        <CellWithLongerContentContainer width="200px">
          <Popover value={row?.firstName ?? ""}>{row?.firstName ?? ""}</Popover>
        </CellWithLongerContentContainer>
      ),
      width: "200px",
    },
    {
      name: t(`table.user.lastname`),
      selector: (row) => row.lastName ?? "",
      cell: (row) => (
        <CellWithLongerContentContainer width="200px">
          <Popover value={row?.lastName ?? ""}>{row?.lastName ?? ""}</Popover>
        </CellWithLongerContentContainer>
      ),
      width: "200px",
    },
    {
      name: t(`table.user.company`),
      grow: 2,
      cell: (row) => (
        <CellWithLongerContentContainer width="200px">
          <Popover value={row?.company ?? ""}>{row?.company ?? ""}</Popover>
        </CellWithLongerContentContainer>
      ),
      width: "200px",
      selector: (row) => row?.company ?? "",
    },
    {
      name: t(`table.user.role`),
      selector: (row) => row.role ?? "",
    },
    {
      name: t(`table.user.action`),
      cell(row, rowIndex, column, id) {
        return (
          <div style={{ display: "flex" }}>
            <IonButton
              size="small"
              color={"danger"}
              onClick={() => onDeleteUser(row.id!, row.username!)}
            >
              <IonIcon icon={trashOutline} />
            </IonButton>
            <IonButton
              size="small"
              onClick={() => {
                setUser(row);
                present();
              }}
            >
              <IonIcon icon={createOutline} />
            </IonButton>
          </div>
        );
      },
    },
  ];

  function onDeleteUser(id: number, name: string) {
    const headerContent = t("toast.delete-user", { name });
    const cancel = t("common.cancel");
    const ok = t("common.ok");
    const failDelete = t("toast.fail-delete-user");

    presentAlert({
      header: headerContent,
      buttons: [
        cancel,
        {
          text: ok,
          handler: () =>
            deleteUser.mutate(id, {
              onSuccess: () => successToast(),
              onError: () => dangerToast(failDelete),
            }),
        },
      ],
    });
  }
  return (
    <BaseTable<UserOutputDTO>
      customStyles={customStyle}
      columns={columns}
      data={props.data}
    />
  );
}
