import { useMutation, useQueryClient } from "@tanstack/react-query";
import jwtDecode from "jwt-decode";
import { Auth } from "../../api";
import { LoginInputDTO } from "../../types/auth";

export default function useLogin() {
  // const queryClient = useQueryClient();

  return useMutation((data: LoginInputDTO) => Auth.login(data), {
    onSuccess: (data) => {
      localStorage.setItem("token", `Bearer ${data.token.jwt}`);
      // const user = jwtDecode(data.token.jwt!);
      // queryClient.setQueryData(["current-user"], user);
      // queryClient.invalidateQueries(["current-user"]);
    },
    // useErrorBoundary: true,
  });
}
