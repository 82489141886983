export const COLORS = {
  red: {
    dark: "#B45050",
    light: "#FEE2E2",
  },
  green: {
    dark: "#065F46",
    light: "#D1FAE5",
  },
  blue: {
    light: "#5eb5ce",
  },
  white: "#fff",
};
