import InventoryTable from "../../components/Tables/InventoryTable";
import { IonCard, IonCol, IonGrid, IonRow } from "@ionic/react";
import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import useProducts from "../../hooks/queries/useProducts";
import Loading from "../../components/Loading";
import { Inventory as InventoryType } from "../../types/inventory";

export default function Inventory() {
  const { t } = useTranslation();
  const products = useProducts();
  const title = t("navigation.inventory");
  const productsSortedByQuantity =
    products?.data?.sort(
      (a: InventoryType, b: InventoryType) => b.quantity - a.quantity,
    ) || [];

  return (
    <>
      <Loading isLoading={products.isLoading} />
      <IonGrid>
        <IonRow>
          <IonCol>
            <PageHeader title={title} />
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonCard>
        <InventoryTable data={productsSortedByQuantity} />
      </IonCard>
    </>
  );
}
