import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useTranslation } from "react-i18next";
import { IonCard, IonCardSubtitle, IonCardContent } from "@ionic/react";
import InfoButton from "../../components/Buttons/InfoButton/InfoButton";
import { OrderReportOutputDTO } from "../../types/orders";
import { formatDate } from "../../utils/date";
import styled from "styled-components";

type LineChartProps = {
  orderReports: OrderReportOutputDTO[];
  title: string;
};

const StyledIonCard = styled(IonCard)`
  position: relative;
  width: inherit;
  max-height: 400px;
  height: 100%;
  border-radius: 20px;
  padding: 20px;
`;

const StyledIonCardContent = styled(IonCardContent)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LineChart = (props: LineChartProps) => {
  const { t } = useTranslation();
  const total: number[] = [];
  const processed: number[] = [];
  const time: string[] = [];
  // TODO zasto je total orders manji broj od processed orders

  if (props.orderReports != null) {
    props.orderReports.forEach((orders) => {
      processed.push(orders.processedOrders);
      time.push(formatDate(orders.timestamp));
      total.push(orders.totalOrders);
    });

    if (time.length === 1) {
      const firstTimeValue = time[0];
      time.push(firstTimeValue);
      processed.push(processed[0]);
      total.push(total[0]);
    }
  }

  const series = [
    {
      name: t("dashboard.line-chart-total"),
      data: total,
    },
    {
      name: t("dashboard.line-chart-processed"),
      data: processed,
    },
  ];

  const options: ApexOptions = {
    xaxis: {
      categories: time,
    },
    stroke: {
      curve: "smooth",
    },
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    legend: {
      horizontalAlign: "right",
    },
  };

  return (
    <StyledIonCard mode="md">
      <InfoButton infoText={t("dashboard.order-reports-info")} />
      <StyledIonCardContent>
        <IonCardSubtitle>
          <h5 style={{ color: "#6E7191", marginLeft: 17 }}>{props.title}</h5>
        </IonCardSubtitle>
      </StyledIonCardContent>
      <Chart options={options} series={series} type="line" height="100%" />
    </StyledIonCard>
  );
};

export default LineChart;
