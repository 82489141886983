import { Title } from "../../ui/Title";
import styled from "styled-components";

const Wrapper = styled.div`
  @media (max-width: 400px) {
    padding: 0;
  }
`;

type PageHeaderProps = {
  title: string;
};
export default function PageHeader(props: PageHeaderProps) {
  return (
    <Wrapper className="ion-padding">
      <Title>{props.title}</Title>
    </Wrapper>
  );
}
