import React from "react";
import CustomLoading from "./CustomLoading";

type LoadingProps = {
  isLoading: boolean;
};

const Loading: React.FC<LoadingProps> = (props: LoadingProps) => {
  return <div>{props.isLoading ? <CustomLoading /> : null}</div>;
};

export default Loading;
