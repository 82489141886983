import { OrderOutputDTO } from "../../../types/orders";
import { TableColumn } from "react-data-table-component";
import BaseTable from "../BaseTable/BaseTable";
import BaseTableHead from "../BaseTable/BaseTableHead";
import OrderDetailsTable from "../OrderTable/OrderDetailsTable";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { getSearchResult } from "../../../utils/filter";
import { CellWithLongerContentContainer } from "../../../ui/common/TableCell";
import { formatDate } from "../../../utils/date";
import Popover from "../../Popover";
import TableInfoButton from "../../Buttons/TableInfoButton";
import { DangerChip, PendingChip, SuccessChip } from "../../Chips";
import { ExportOrdersToExcel } from "../../ExportIndex/ExportOrdersToExcel";
import CellWithInfo from "../CellWithInfo/CellWithInfo";

const stringSort = (firstStr: string, secondStr: string) => {
  const first = firstStr.trim();
  const second = secondStr.trim();
  const collator = new Intl.Collator("en-US");
  return collator.compare(first, second);
};

enum Statuses {
  Fulfilled = "Delivered",
  AwaitingFulfillment = "Awaiting fulfillment",
  Deleted = "Canceled",
  Geliefert = "Geliefert",
  InBearbeitung = "In Bearbeitung",
  GelÃ¶scht = "GelÃ¶scht",
}

const customStyle = {
  headCells: {
    style: {
      fontFamily: "Poppins",
      fontSize: "13px",
      overflow: "visible",
    },
  },
  cells: {
    style: {
      fontFamily: "Poppins",
      fontSize: "13px",
      color: "#6E7191",
    },
  },
};

type OrdersTableProps = {
  data: OrderOutputDTO[];
};

export default function OrderTable(props: OrdersTableProps) {
  const { t } = useTranslation();
  const orderNumber = t("table.order.order-number");
  const orderNumberInfo = t("table.order.order-number-info");
  const importDate = t("table.order.import-date");
  const importDateInfo = t("table.order.import-date-info");
  const salesChannel = t("table.order.sales-channel");
  const customerName = t("table.order.customer-name");
  const customerNameInfo = t("table.order.customer-name-info");
  const deliveryAddress = t("table.order.delivery-address");
  const deliveryAddressInfo = t("table.order.delivery-address-info");
  const deliveryDate = t("table.order.delivery-date");
  const deliveryDateInfo = t("table.order.delivery-date-info");
  const orderStatus = t("table.order.order-status");
  const orderStatusInfo = t("table.order.order-status-info");
  const trackingNumber = t("table.order.tracking-number");
  const trackingNumberInfo = t("table.order.tracking-number-info");
  const delivered = t("table.order.orderStatuses.delivered");
  const awaiting = t("table.order.orderStatuses.awaiting");
  const canceled = t("table.order.orderStatuses.canceled");
  const pageTitle = t("table.recent-orders.title");
  const contactSupport = t("table.recent-orders.contact-support");

  const CELL_STATUSES: Record<Statuses, React.ReactElement> = {
    [Statuses.AwaitingFulfillment]: <PendingChip text={awaiting} />,
    [Statuses.InBearbeitung]: <PendingChip text={awaiting} />,
    [Statuses.Fulfilled]: <SuccessChip text={delivered} />,
    [Statuses.Geliefert]: <SuccessChip text={delivered} />,
    [Statuses.Deleted]: <DangerChip text={canceled} />,
    [Statuses.GelÃ¶scht]: <DangerChip text={canceled} />,
  };

  const [searchInput, setSearchInput] = useState<string>("");

  const searchResult = getSearchResult<OrderOutputDTO>(props.data, searchInput);

  const isOrderDeliveryDateValid = (dateString: string): boolean => {
    if (!dateString) {
      return false;
    }

    const date = new Date(dateString);

    //every date below 1970 can be assumed is wrong (mostly used to avoid min dates 01-01-1)
    if (date < new Date("1970-01-01")) {
      return false;
    }

    return true;
  };

  const columns: TableColumn<OrderOutputDTO>[] = [
    {
      name: <CellWithInfo cellText={orderNumber} infoText={orderNumberInfo} />,
      selector: (row) => row?.orderNumber,
      sortable: false,
      grow: 1,
      allowOverflow: true,
      ...(window.innerWidth < 1483 ? { width: "160px" } : {}),
    },
    {
      name: (
        <CellWithInfo
          cellText={deliveryAddress}
          infoText={deliveryAddressInfo}
        />
      ),
      allowOverflow: true,
      selector: (row) => row.deliveryAddress,
      sortable: false,
      ...(window.innerWidth < 1483 ? { width: "180px" } : {}),
      grow: 1,

      cell: (row) => (
        <CellWithLongerContentContainer>
          {row.deliveryAddress}
        </CellWithLongerContentContainer>
      ),
    },
    // {
    //   name: salesChannel,
    //   selector: (row) => row.salesChannel,
    //   sortable: false,
    // },
    {
      name: (
        <CellWithInfo cellText={customerName} infoText={customerNameInfo} />
      ),
      selector: (row) => row.customerName,
      ...(window.innerWidth < 1483 ? { width: "160px" } : {}),
      grow: 1,
      cell: (row) => (
        // <CellWithLongerContentContainer width="250px">
        <div>{row.customerName}</div>
        // </CellWithLongerContentContainer>
      ),
      sortable: false,
    },
    {
      name: <CellWithInfo cellText={importDate} infoText={importDateInfo} />,
      selector: (row) => row.importDate,
      format: (row) => formatDate(row.importDate),
      sortable: true,
      ...(window.innerWidth < 1483 ? { width: "140px" } : {}),
      grow: 1,
    },
    {
      name: (
        <CellWithInfo cellText={deliveryDate} infoText={deliveryDateInfo} />
      ),
      selector: (row) => row.deliveryDate,
      format: (row) =>
        isOrderDeliveryDateValid(row.deliveryDate)
          ? formatDate(row.deliveryDate)
          : null,
      ...(window.innerWidth < 1483 ? { width: "140px" } : {}),
      grow: 1,
      sortable: true,
    },
    {
      name: <CellWithInfo cellText={orderStatus} infoText={orderStatusInfo} />,
      ...(window.innerWidth < 1483 ? { width: "165px" } : {}),
      grow: 1,
      cell(row) {
        return row.orderStatus != null
          ? CELL_STATUSES[row.orderStatus as Statuses]
          : "";
      },
      sortable: true,
      sortFunction: (rowA: any, rowB: any) =>
        stringSort(rowA.orderStatus, rowB.orderStatus),
    },
    {
      name: (
        <CellWithInfo cellText={trackingNumber} infoText={trackingNumberInfo} />
      ),
      selector: (row) => row?.trackingNumber ?? "",
      ...(window.innerWidth < 1483 ? { width: "160px" } : {}),
      grow: 1,
      // TODO this for every table that has width
      // ...(window.innerWidth < 1483 ? { grow: 1 } : {}),
      cell: (row) => (
        <CellWithLongerContentContainer>
          <Popover
            value={row?.trackingURLs?.map((tn, index) => {
              if (tn?.trackingProvider === "Spedition") {
                if (index === 0) {
                  return contactSupport;
                }
              } else {
                return (
                  <a
                    className="ion-margin"
                    style={{ display: "block" }}
                    key={tn?.trackingNumber}
                    rel="noopener noreferrer"
                    target="_blank"
                    href={
                      tn?.trackingProviderURL === ""
                        ? undefined
                        : tn?.trackingProviderURL
                    }
                  >
                    {tn?.trackingNumber}
                  </a>
                );
              }
            })}
          >
            {row?.trackingNumber}
          </Popover>
        </CellWithLongerContentContainer>
      ),
      sortable: false,
    },
  ];

  return (
    <>
      <ExportOrdersToExcel orders={props.data ?? []} />
      <BaseTableHead pageTitle={pageTitle} setSearchInput={setSearchInput} />
      <BaseTable
        data={searchResult}
        columns={columns}
        customStyles={customStyle}
        expandableRows={true}
        expandableRowDisabled={(row) => row.items[0] === null} // add this line
        expandableRowsComponent={OrderDetailsTable}
      />
    </>
  );
}

type CellHeaderProps = {
  text: string;
  infoText: string;
  transformTranslate?: string;
};

export const CellHeader = (props: CellHeaderProps) => {
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <TableInfoButton
        infoText={props.infoText}
        transformTranslate={props.transformTranslate}
      />
      <span style={{ whiteSpace: "nowrap" }}>{props.text}</span>
    </div>
  );
};
