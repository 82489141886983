import * as yup from "yup";

import { ValidationError } from "yup";
import { DIGIT, LOWERCASE, MIN_LENGTH, SYMBOL, UPPERCASE } from "./regex";

export async function validateEmail(email: string) {
  let schema = yup.object().shape({
    email: yup
      .string()
      .email("Please provide valid email")
      .required("Email is required!"),
  });
  return schema
    .isValid({
      email,
    })
    .then(function (valid) {
      return valid;
    });
}

type ValidatePasswordReturnType = {
  isValid: boolean;
  errorMessage: string;
};

export async function validatePassword(
  password: string | undefined,
  confirmPassword: string | undefined,
): Promise<ValidatePasswordReturnType> {
  let schema = yup.object().shape({
    password: yup
      .string()
      .required("password-requirements.password-required")
      .matches(UPPERCASE, "password-requirements.uppercase-letter")
      .matches(LOWERCASE, "password-requirements.lowercase-letter")
      .matches(DIGIT, "password-requirements.number")
      .matches(SYMBOL, "password-requirements.special-character")
      .min(9, "password-requirements.min-length"),
    confirmPassword: yup
      .string()
      .required("validation.confirm-rassword-required")
      .oneOf([yup.ref("password")], "validation.passwords-do-not-match"),
  });

  try {
    await schema.validate({ password, confirmPassword });
    return { isValid: true, errorMessage: "" };
  } catch (err: any) {
    const error: ValidationError = err;
    return { isValid: false, errorMessage: error.message };
  }
}

// /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}\[\]+=|<>,';\-\`_~\\])[A-Za-z\d@$!%*?&]{9,}$/,
