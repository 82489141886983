import { useMutation } from "@tanstack/react-query";
import { Auth } from "../../api";
import { ChangePasswordData } from "../../types/auth";

export default function useChangePassword() {
  return useMutation((data: ChangePasswordData) => Auth.changePassword(data), {
    onSuccess: () => {},
    onError: () => {}
  });
}
