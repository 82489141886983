import { Redirect, Route } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./index.css";

import PrivateRoute from "./routes/PrivateRoute";

import Desktop from "./layouts/Desktop";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Unauthorized from "./pages/Unauthorized";
import { adminNavItems, employeeNavItems } from "./displays/navigation-items";
import { UserRole } from "./types/users";
import Users from "./pages/Users";
import Orders from "./pages/OrderOverview";
import Inventory from "./pages/Inventory";
import Documents from "./pages/Documents";
import Organizations from "./pages/Organizations";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import React from "react";
import ProfileMenu from "./components/ProfileMenu/ProfileMenu";
import EmailConfirmation from "./pages/EmailConfirmation/EmailConfirmation";

const App: React.FC = () => {
  return (
    <React.Fragment>
      <ProfileMenu />
      <Route path="/login" component={Login} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/email-confirmation" component={EmailConfirmation} />
      <PrivateRoute
        exact
        path={["/users", "/organizations"]}
        allowedRoles={[UserRole.Admin]}
      >
        <Desktop navItems={adminNavItems} defaultSelectedNavItem="users">
          <Route path={"/users"} component={Users} />
          <Route path={"/organizations"} component={Organizations} />
        </Desktop>
      </PrivateRoute>
      <Route path="/unauthorized" component={Unauthorized} />
      <PrivateRoute
        exact
        path={["/dashboard", "/order-overview", "/inventory", "/documents"]}
        allowedRoles={[UserRole.Employee]}
      >
        <Desktop navItems={employeeNavItems} defaultSelectedNavItem="dashboard">
          <Route path={"/dashboard"} component={Dashboard} />
          <Route path={"/order-overview"} component={Orders} />
          <Route path={"/inventory"} component={Inventory} />
          <Route path={"/documents"} component={Documents} />
        </Desktop>
      </PrivateRoute>

      <Route exact path="/" render={() => <Redirect to={"/login"} />} />
    </React.Fragment>
  );
};

export default App;
