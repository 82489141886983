import {
  IonContent,
  IonPage,
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonButton,
  IonIcon,
  IonAlert,
  IonText,
} from "@ionic/react";
import { Input } from "../../ui/common/Input";
import React, { useEffect, useState } from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import { lockClosedOutline, personOutline } from "ionicons/icons";
import styled from "styled-components";

import { Link as ReactLink, RouteComponentProps } from "react-router-dom";
import useLogin from "../../hooks/mutations/useLogin";
import { useAuthContext } from "../../context/AuthContext";
import jwtDecode from "jwt-decode";
import { CurrentUserType } from "../../types/users";
import { useTranslation } from "react-i18next";
import TranslationBanners from "../../components/TranslationBanners/TranslationBanners";
import { NETWORK_ERROR } from "../../utils/api-message";
import { ERR_NETWORK } from "../../utils/error-codes";
import Logo from "../../images/logo.png";

const Button = styled(IonButton)`
  --background: #2196f3;
`;

const Link = styled(ReactLink)`
  cursor: pointer;
  color: #2196f3;
  text-decoration: none;
`;

const StyledIonRow = styled(IonRow)`
  flex-direction: column;
  gap: 30px;
  align-items: center;
`;

const StyledIonCol = styled(IonCol)`
  gap: 30;
  display: flex;
  flex-direction: column;
`;

const StyledImage = styled.img`
  margin-top: 180px;
`;

const Login: React.FC<RouteComponentProps> = (props) => {
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isError, setIserror] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  // const [alertHeader, setAlertHeader] = useState<string>();
  // const [alertMessage, setMessage] = useState<string>();
  const { t, i18n } = useTranslation();
  const alertHeader = t("alert.login-error-header");
  const alertMessage = t("alert.login-error-message");
  const dismiss = t("common.dismiss");
  const {
    isCurrentUserAdmin,
    isCurrentUserEmployee,
    currentUser,
    setCurrentUser,
  } = useAuthContext();
  const login = useLogin();

  const currentLanguage: string | null =
    localStorage.getItem("current-language");
  const initialLanguage: string | null =
    localStorage.getItem("initial-language");

  const handleLogin = (e: any) => {
    e.preventDefault();
    return login.mutate(
      {
        username: userName,
        password: password,
      },
      {
        onSuccess: (data) => {
          const user: CurrentUserType = jwtDecode(data.token.jwt!);
          setCurrentUser(user);
          if (isCurrentUserAdmin) props.history.push("/users");
          if (isCurrentUserEmployee) props.history.push("/dashboard");
        },
        onError: (error: any) => {
          if (error && error?.code === ERR_NETWORK) {
            setErrorMessage(NETWORK_ERROR);
          }
          if (error?.response?.data["errors"] != null) {
            setIserror(true);
          }
        },
      }
    );
  };

  if (errorMessage) throw new Error(NETWORK_ERROR);

  useEffect(() => {
    if (isCurrentUserAdmin) props.history.push("/users");
    if (isCurrentUserEmployee) props.history.push("/dashboard");
  }, [currentUser]);

  function changeLanguage() {
    const lng = navigator.language;
    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage);
      return;
    }
    if (initialLanguage) {
      i18n.changeLanguage(initialLanguage);
      return;
    }
    if (lng === "de") {
      i18n.changeLanguage("de");
    } else {
      i18n.changeLanguage("en-US");
    }
  }

  useEffect(() => {
    changeLanguage();
  }, [currentLanguage, initialLanguage]);

  return (
    <IonPage>
      <IonContent className="ion-text-center">
        <div className="ion-padding">
          <TranslationBanners />
        </div>
        <IonGrid className="ion-no-padding">
          <StyledIonRow className="ion-justify-content-center">
            <StyledImage src={Logo} alt="logo" style={{ marginTop: "200px" }} />
            <IonCol size="12" sizeMd="6" sizeXl="4">
              <IonCard mode="md">
                <form onSubmit={handleLogin}>
                  <IonCardContent>
                    <IonGrid>
                      <IonRow className="ion-justify-content-center">
                        <IonCol>
                          <IonAlert
                            isOpen={isError}
                            onDidDismiss={() => setIserror(false)}
                            cssClass="my-custom-class"
                            header={alertHeader}
                            message={alertMessage}
                            buttons={[dismiss]}
                          />
                        </IonCol>
                      </IonRow>
                      <IonRow className="ion-justify-content-center">
                        <IonCol size="12" className="ion-margin-bottom">
                          <IonItem fill="outline">
                            <IonLabel position="floating">
                              {t("auth.username")}
                            </IonLabel>
                            <IonIcon slot="start" icon={personOutline} />
                            <Input
                              type="text"
                              value={userName}
                              onIonChange={(e) => setUserName(e.detail.value!)}
                            ></Input>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow className="ion-justify-content-center">
                        <IonCol size="12" className="ion-margin-bottom">
                          <IonItem fill="outline">
                            <IonLabel position="floating">
                              {t("auth.password")}
                            </IonLabel>
                            <IonIcon slot="start" icon={lockClosedOutline} />
                            <Input
                              type="password"
                              value={password}
                              onIonChange={(e) => setPassword(e.detail.value!)}
                              onKeyPress={async (e) => {
                                if (e.key === "Enter") {
                                  handleLogin(e);
                                }
                              }}
                            ></Input>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                      <IonRow className="ion-justify-content-center">
                        <StyledIonCol>
                          <IonText style={{ textAlign: "left" }}>
                            {t("auth.cant-login")}
                            {"? "}
                            <Link to={"/forgot-password"}>
                              {t("auth.forgot-password")}
                            </Link>
                          </IonText>
                          <Button type="submit" expand="block">
                            {t("auth.login")}
                          </Button>
                        </StyledIonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </form>
              </IonCard>
            </IonCol>
          </StyledIonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;
