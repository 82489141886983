import { IonIcon, IonItem, useIonModal } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAuthContext } from "../../context/AuthContext";
import { useMenuContext } from "../../context/MenuContext";
import useUser from "../../hooks/queries/useUser";
import { EditIcon, UnlockIcon } from "../../icons";
import ChangePassword from "../ModalsContents/ChangePassword";
import CreateOrEditUserModalContent from "../ModalsContents/CreateOrEditUser/CreateOrEditUser";
import avatar from "../../icons/avatar.svg";

const MenuContainer = styled.div<{ isOpen: boolean }>`
  width: 380px;
  background: #fff;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  transform: translateX(${(props) => (props.isOpen ? "0" : "100000px")});
  transition: ${(props) => (props.isOpen ? "" : "transform 1s ease-in-out")};
  @media (max-width: 400px) {
    width: 100vw;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const Overlay = styled.div<{ isOpen: boolean }>`
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 900;
  transform: translateX(${(props) => (props.isOpen ? "0" : "100000px")});
  opacity:${(props) => (props.isOpen ? "1" : "0")}
  transition: opacity 1s ease-in;
`;

const Flex = styled.div<{ justify: "space-between" | "center" | "flex-start" }>`
  display: flex;
  justify-content: ${(props) => props.justify ?? "center"};
  align-items: center;
  width: 100%;
`;
const DetailsText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #6e7191;
`;

const NameText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #6e7191;
`;

const EmailText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #6e7191;
`;

const Text = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #6e7191;
  margin-left: 22px;
`;

const Circle = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: #fff;
  outline: 2px solid #bface0;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 400px) {
    width: 110px;
    height: 110px;
  }
`;

const CircleInner = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: url(${avatar});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 400px) {
    width: 90px;
    height: 90px;
  }
`;

const Space = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
`;
const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #d9dbe9;
`;

export default function ProfileMenu() {
  const { isOpen, setIsOpen } = useMenuContext();
  const { t } = useTranslation();
  const { currentUser } = useAuthContext();
  const user = useUser(
    currentUser?.nameid ? Number(currentUser.nameid) : undefined,
  );

  const [present, dismiss] = useIonModal(ChangePassword, {
    onDismiss: (data: any, role: string) => dismiss(data, role),
  });

  const [presentEdit, dismissEdit] = useIonModal(CreateOrEditUserModalContent, {
    onDismiss: (data: any, role: string) => dismissEdit(data, role),
    title: 'editProfile',
    user: currentUser
  });

  return (
    <>
      <Overlay isOpen={isOpen} onClick={() => setIsOpen(false)} />
      <MenuContainer isOpen={isOpen}>
        <IonItem lines="none">
          <IonIcon
            style={{ cursor: "pointer" }}
            slot="end"
            size="large"
            icon={closeOutline}
            onClick={() => setIsOpen(false)}
          />
        </IonItem>
        <div style={{ padding: "5px 30px" }}>
          <Flex justify="center">
            <DetailsText>{t("profile.account-details")}</DetailsText>
          </Flex>
          <Space height={30} />
          <Flex justify="center">
            <Circle>
              <CircleInner />
            </Circle>
          </Flex>
          <Space height={30} />
          <NameText>{`${user?.data?.firstName} ${user?.data?.lastName}`}</NameText>
          <EmailText>{user?.data?.email}</EmailText>
          <Space height={20} />
          <Divider />
          <Space height={40} />
          <Flex
            justify="flex-start"
            onClick={() => present()}
            style={{ cursor: "pointer" }}
          >
            <UnlockIcon />
            <Text>{t("profile.change-password")}</Text>
          </Flex>
          <Flex
            justify="flex-start"
            onClick={() => presentEdit()}
            style={{ cursor: "pointer", marginLeft: 5 }}
          >
            <EditIcon />
            <Text>{t("profile.edit-profile")}</Text>
          </Flex>                      
        </div>
      </MenuContainer>
    </>
  );
}
