import { useQuery } from "@tanstack/react-query";
import { Users } from "../../api";
import { useAuthContext } from "../../context/AuthContext";

export default function useUser(id: number | undefined) {
  const { isCurrentUserAdmin } = useAuthContext();
  return useQuery(
    ["user", id],
    async () => {
      return await Users.getUserById(id!);
    },
    {
      enabled: id !== undefined || isCurrentUserAdmin,
    },
  );
}
