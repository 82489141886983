import { COLORS } from "../../../constants/colors";
import BaseChip from "../BaseChip/BaseChip";

type PendingChipProps = {
  text: string;
};

export default function PendingChip(props: PendingChipProps) {
  return (
    <BaseChip
      color={COLORS.white}
      background={COLORS.blue.light}
      text={props.text}
    />
  );
}
