import InvoicesTable from "../../components/Tables/InvoicesTable";
import DeliveryTable from "../../components/Tables/DeliveryTable";
import { IonCard, IonCol, IonGrid, IonRow } from "@ionic/react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../components/PageHeader";
import useDeliveryNotes from "../../hooks/queries/useDeliveryNotes";
import useInvoices from "../../hooks/queries/useInvoices";
import Loading from "../../components/Loading/Loading";
import { Delivery } from "../../types/delivery";
import { InvoiceOutputDto } from "../../types/invoices";

export default function Documents() {
  const { t } = useTranslation();
  const deliveryNotes = useDeliveryNotes();
  const invoices = useInvoices();
  const title = t("navigation.documents");
  let sortedInvoices: InvoiceOutputDto[] = [];
  if (invoices?.data != null) {
    sortedInvoices = [...invoices.data].sort(
      (a: unknown, b: unknown) =>
        new Date((b as InvoiceOutputDto).invoiceDate!).getTime() -
        new Date((a as InvoiceOutputDto).invoiceDate!).getTime(),
    );
  }
  let sortedDelivery: Delivery[] = [];

  if (deliveryNotes?.data != null) {
    sortedDelivery = [...deliveryNotes.data].sort(
      (a: unknown, b: unknown) =>
        new Date((b as Delivery).deliveryNoteDate!).getTime() -
        new Date((a as Delivery).deliveryNoteDate!).getTime(),
    );
  }

  return (
    <>
      <Loading isLoading={deliveryNotes.isLoading || invoices.isLoading} />
      <IonGrid>
        <IonRow className="ion-justify-content-end">
          <IonCol>
            <PageHeader title={title} />
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonCard>
        <InvoicesTable data={sortedInvoices ?? []} />
        <DeliveryTable data={sortedDelivery ?? []} />
      </IonCard>
    </>
  );
}
