import { useQuery } from "@tanstack/react-query";
import { Companies } from "../../api";
import { useAuthContext } from "../../context/AuthContext";

export default function useCompanies() {
  const { isCurrentUserAdmin } = useAuthContext();
  return useQuery(
    ["companies"],
    async () => {
      return await Companies.getCompanies();
    },
    {
      enabled: isCurrentUserAdmin,
    },
  );
}
