import { TableColumn } from "react-data-table-component";
import { OrderDetails } from "../../../types/orderDetails";
import BaseTable from "../BaseTable";
import { CellWithLongerContentContainer } from "../../../ui/common/TableCell";
import Popover from "../../Popover/Popover";
import { useTranslation } from "react-i18next";

const customStyle = {
  headCells: {
    style: {
      fontFamily: "Poppins",
      fontSize: "14px",
      color: "#6E7191",
      marginLeft: "48px",
      backgroundColor: "#f4f6f8",
    },
  },
  cells: {
    style: {
      fontFamily: "Poppins",
      fontSize: "14px",
      color: "#6E7191",
      marginLeft: "48px",
    },
  },
};

export default function OrderTable(data: any) {
  const { t } = useTranslation();
  const itemNumber = t("table.order.order-details.itemNumber");
  const itemDescription = t("table.order.order-details.itemDescription");
  const quantityOrdered = t("table.order.order-details.quantityOrdered");

  const columns: TableColumn<OrderDetails>[] = [
    {
      name: itemNumber,
      selector: (row) => row?.itemNumber ?? "",
      sortable: false,
      width: "200px",
    },
    {
      name: itemDescription,
      selector: (row) => row?.itemDescription ?? "",
      sortable: false,
      width: "350px",
      cell: (row) => (
        <CellWithLongerContentContainer>
          <Popover value={row.itemDescription}>{row.itemDescription}</Popover>
        </CellWithLongerContentContainer>
      ),
    },
    {
      name: quantityOrdered,
      selector: (row) => row?.quantityOrdered ?? "",
      sortable: false,
      width: "200px",
      right: true,
    },
  ];

  return (
    <BaseTable
      data={data.data.items ?? []}
      pagination={true}
      columns={columns}
      customStyles={customStyle}
    />
  );
}
