import { ReactNode } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { UserRole } from "../../types/users";

type PrivateRouteType = RouteProps & {
  allowedRoles: UserRole[];
  children: ReactNode;
};

export default function PrivateRoute({
  component: Component,
  children,
  allowedRoles = [],
  ...rest
}: PrivateRouteType) {
  const { currentUser, logout } = useAuthContext();
  const hasRole = currentUser?.role
    ? allowedRoles?.includes(currentUser.role)
    : false;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!currentUser)
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        if (!hasRole) return <Redirect to={{ pathname: "/unauthorized" }} />;
        return children;
      }}
    ></Route>
  );
}
