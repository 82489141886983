import { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import BaseTable from "../BaseTable";
import { OrderWithItemCountDTO } from "../../../types/orders";
import styled from "styled-components";
import InfoButton from "../../Buttons/InfoButton/InfoButton";

export const DashboardTitle = styled.h4<{ marginLeft?: string }>`
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0")};
  font-family: Poppins;
  font-size: 18px;
  @media (max-width: 400px) {
    font-size: 16px;
  }
`;

type InvoiceTableProps = {
  data: OrderWithItemCountDTO[];
};

export default function InvoicesTable(props: InvoiceTableProps) {
  const { t } = useTranslation();
  const labelOrderId = t("table.dashboard-table.order-id");
  const title = t("table.dashboard-table.title");
  const missingProcessedOrders = t("table.dashboard-table.missing-data");

  const labelOrderName = t("table.dashboard-table.order-name");
  const labelOrderType = t("table.dashboard-table.order-type");
  const labelOrderQuantity = t("table.dashboard-table.order-quantity");

  const customStyle = {
    headCells: {
      style: {
        fontFamily: "Poppins",
        fontSize: "14px",
        color: "#6E7191",
      },
    },
    cells: {
      style: {
        fontFamily: "Poppins",
        fontSize: "14px",
        color: "#6E7191",
      },
    },
    rows: {
      style: {
        minHeight: "40px",
      },
    },
  };

  const columns: TableColumn<OrderWithItemCountDTO>[] = [
    {
      name: labelOrderId,
      selector: (row) => row.orderNumber ?? "",
      sortable: false,
    },
    // {
    //   name: labelOrderName,
    //   selector: (row) => row.orderName,
    //   sortable: false,
    // },
    // {
    //   name: labelOrderType,
    //   selector: (row) => row.orderType,
    //   sortable: false,
    // },
    {
      name: labelOrderQuantity,
      selector: (row) => row.itemsCount ?? "",
      sortable: false,
      cell: (row) => <div style={{ margin: "0 auto" }}>{row.itemsCount}</div>,
    },
  ];

  return (
    <>
      <InfoButton infoText={t("table.dashboard-table.info")} />
      <DashboardTitle marginLeft="15px">
        {props.data.length ? title : missingProcessedOrders}
      </DashboardTitle>
      <BaseTable
        data={props.data}
        columns={columns}
        customStyles={customStyle}
        pagination={false}
      />
    </>
  );
}
