import { IonIcon } from "@ionic/react";
import { informationCircleSharp } from "ionicons/icons";
import styled from "styled-components";
import TablePopover from "./TablePopover";

const Info = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 200;
  top: 0;
  right: 0;
`;

type InfoButtonProps = {
  infoText: string;
  transformTranslate?: string;
};

export default function TableInfoButton(props: InfoButtonProps) {
  return (
    <Info
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <TablePopover
        value={props.infoText}
        translateX={"0px"}
        translateY="-16px"
        transformTranslate={props.transformTranslate}
      >
        <IonIcon size="small" icon={informationCircleSharp} color="primary" />
      </TablePopover>
    </Info>
  );
}
