import { useIonToast } from "@ionic/react";

const useWarningToast = () => {
  const [presentToast] = useIonToast();

  return (message?: string, duration?: number) => {
    return presentToast({
      message: message ?? "Warning!",
      duration: duration ?? 2000,
      position: "top",
      color: "warning",
    });
  };
};
export default useWarningToast;
