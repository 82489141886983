import { IonIcon } from "@ionic/react";
import {
  informationCircleOutline,
  informationCircleSharp,
} from "ionicons/icons";
import styled from "styled-components";
import Popover from "../../Popover";

const Info = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  // padding: 10px;
  // margin-top: 10px;
  // margin-right: 10px;
`;

type InfoButtonProps = {
  infoText: string;
};

export default function InfoButton(props: InfoButtonProps) {
  return (
    <Info>
      <Popover value={props.infoText} infoText={true}>
        <IonIcon size="small" icon={informationCircleSharp} color="primary" />
      </Popover>
    </Info>
  );
}
