import styled from "styled-components";

export const Title = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 46px;
  color: #0b154d;
  @media (max-width: 400px) {
    font-size: 22px;
    line-height: 36px;
  }
`;

export const SubTitle = styled.h2`
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
  color: #0b154d;
`;
