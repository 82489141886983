interface AverageNumberIconI {
  width: string;
  height: string;
}

const AverageNumberIcon: React.FC<AverageNumberIconI> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44 22.2V13.8C44 6.8 41.2 4 34.2 4H25.8C18.8 4 16 6.8 16 13.8V16H22.2C29.2 16 32 18.8 32 25.8V32H34.2C41.2 32 44 29.2 44 22.2Z"
      stroke="#0BAB52"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32 34.2V25.8C32 18.8 29.2 16 22.2 16H13.8C6.8 16 4 18.8 4 25.8V34.2C4 41.2 6.8 44 13.8 44H22.2C29.2 44 32 41.2 32 34.2Z"
      stroke="#0BAB52"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1562 29.9996L16.0562 33.8996L23.8363 26.0996"
      stroke="#0BAB52"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  // </div>
);

export default AverageNumberIcon;
