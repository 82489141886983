import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Companies } from "../../api";
import { useAuthContext } from "../../context/AuthContext";

export default function useSyncCompanies() {
  const { isCurrentUserAdmin } = useAuthContext();
  const queryClient = useQueryClient();
  return useQuery(
    ["companies-sync"],
    async () => {
      return await Companies.syncCompanies();
    },
    {
      enabled: isCurrentUserAdmin,
      onSuccess() {
        queryClient.invalidateQueries(["companies"]);
      },
    },
  );
}
