import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useId } from "react";
import { Users } from "../../api";

export default function useDeleteUser() {
  const queryClient = useQueryClient();
  return useMutation((userId: number) => Users.deleteUser(userId), {
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
    },
  });
}
