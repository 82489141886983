import { IonItem, IonAvatar } from "@ionic/react";
import { useMenuContext } from "../../context/MenuContext";
import avt from "../../icons/avatar.svg";

export default function Avatar() {
  const { setIsOpen } = useMenuContext();
  return (
    <IonItem
      className="ion-no-line"
      lines="none"
      onClick={() => setIsOpen(true)}
    >
      <IonAvatar slot="start">
        <img
          alt="Silhouette of a person's head"
          src={avt}
          style={{
            cursor: "pointer",
            width: "100%",
            height: "100%",
          }}
        />
      </IonAvatar>
    </IonItem>
  );
}
