interface OpenOrdersIconI {
  width: string;
  height: string;
}

const OpenOrdersIcon: React.FC<OpenOrdersIconI> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42 25.65V19.35C42 14.1 39.9 12 34.65 12H28.35C23.1 12 21 14.1 21 19.35V21H25.65C30.9 21 33 23.1 33 28.35V33H34.65C39.9 33 42 30.9 42 25.65Z"
      stroke="#0B154D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33 34.65V28.35C33 23.1 30.9 21 25.65 21H19.35C14.1 21 12 23.1 12 28.35V34.65C12 39.9 14.1 42 19.35 42H25.65C30.9 42 33 39.9 33 34.65Z"
      stroke="#0B154D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18 29.4468L25.4717 28.7375"
      stroke="#0B154D"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18 34.4468L25.4717 33.7375"
      stroke="#0B154D"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default OpenOrdersIcon;
