import {
  IonApp,
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
} from "@ionic/react";
import App from "../../App";
import {
  employeeNavItems,
  adminNavItems,
} from "../../displays/navigation-items";
import { useAuthContext } from "../../context/AuthContext";
import { UserRole } from "../../types/users";
import { useLocation } from "react-router";
import { useEffect, useState, useTransition } from "react";
import styled from "styled-components";
import { logOutOutline } from "ionicons/icons";
import { useMenuContext } from "../../context/MenuContext";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import { SignOutIcon } from "../../icons/navigation-icons";

const IconWrapper = styled.span<{ background: string }>`
  padding: 8px;
  border-radius: 10px;
  background: ${({ background }) => background};
`;

export default function RealApp() {
  const { currentUser } = useAuthContext();
  const location = useLocation();
  const { logout } = useAuthContext();
  const [isPending, startTransition] = useTransition();
  const [tab, setTab] = useState<string>("dashboard");
  const { setIsOpen } = useMenuContext();

  function selectTab(nextTab: string) {
    startTransition(() => {
      setTab(nextTab);
      setIsOpen(false);
    });
    if (nextTab === "login") {
      logout();
    }
  }

  useEffect(() => {
    if (location.pathname !== "login") {
      setTab(location.pathname.split("/")[1]);
    }
  }, [location]);

  return (
    <IonApp>
      <IonTabs>
        <IonRouterOutlet>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </IonRouterOutlet>
        <IonTabBar slot="bottom" className="ion-hide-lg-up">
          {currentUser?.role === UserRole.Employee
            ? [...employeeNavItems, { linkTo: "login", icon: SignOutIcon }].map(
                (item) => {
                  const Icon = item.icon;

                  return (
                    <IonTabButton
                      key={item.linkTo}
                      tab={item.linkTo}
                      href={`/${item.linkTo}`}
                      onClick={() => selectTab(item.linkTo)}
                    >
                      <IconWrapper
                        background={
                          tab === item.linkTo
                            ? "rgba(21, 137, 173, 0.2)"
                            : "transparent"
                        }
                      >
                        <Icon
                          width={20}
                          height={20}
                          active={tab === item.linkTo}
                        />
                      </IconWrapper>
                    </IonTabButton>
                  );
                }
              )
            : currentUser?.role === UserRole.Admin
            ? [...adminNavItems, { linkTo: "login", icon: logOutOutline }].map(
                (item) => {
                  return (
                    <IonTabButton
                      key={item.linkTo}
                      tab={item.linkTo}
                      href={`/${item.linkTo}`}
                      onClick={() => {
                        selectTab(item.linkTo);
                      }}
                    >
                      <IconWrapper
                        background={
                          tab === item.linkTo ? "#1282b2" : "transparent"
                        }
                      ></IconWrapper>
                    </IonTabButton>
                  );
                }
              )
            : null}
        </IonTabBar>
      </IonTabs>
    </IonApp>
  );
}
