import jwtDecode from "jwt-decode";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router";
import { CurrentUserType } from "../types/users";
import { isAdmin, isEmployee } from "../utils/roles";

type AuthContextType = {
  currentUser: CurrentUserType | null;
  logout: () => void;
  isCurrentUserAdmin: boolean;
  isCurrentUserEmployee: boolean;
  setCurrentUser: React.Dispatch<React.SetStateAction<CurrentUserType | null>>;
};

const AuthContext = createContext({} as AuthContextType);

export function useAuthContext() {
  return useContext(AuthContext);
}

type Props = PropsWithChildren & {
  removeQueries: () => void;
};

export function AuthProvider(props: Props) {
  const token = localStorage.getItem("token");
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState<CurrentUserType | null>(() => {
    if (!token) return null;
    return jwtDecode(token);
  });

  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExp");
    props.removeQueries();
    setCurrentUser(null);
    history.push("/login");
  }

  useEffect(() => {
    if (token) {
      const user: CurrentUserType = jwtDecode(token);
      localStorage.setItem("tokenExp", user.exp.toString());
      setCurrentUser(user);
    }
  }, [token]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        logout,
        isCurrentUserAdmin: isAdmin(currentUser),
        isCurrentUserEmployee: isEmployee(currentUser),
        setCurrentUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
