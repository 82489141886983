import { useIonToast } from "@ionic/react";

const useDangerToast = () => {
  const [presentToast] = useIonToast();

  return (message?: string, duration?: number) => {
    return presentToast({
      message: message ?? "Error!",
      duration: duration ?? 2000,
      position: "top",
      color: "danger",
    });
  };
};
export default useDangerToast;
