import { Products } from "../../api";
import { useQuery } from "@tanstack/react-query";

export default function useProducts() {
  return useQuery(
    ["products"],
    async () => {
      return await Products.getProducts();
    }
  );
}
