import { IonIcon } from "@ionic/react";
import { informationCircleSharp } from "ionicons/icons";
import styled from "styled-components";
import InfoButtonPopover from "../../InfoButtonPopover";

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type CellWithInfoProps = {
  cellText: string;
  infoText: string;
};

export default function CellWithInfo(props: CellWithInfoProps) {
  return (
    <Flex>
      <span>{props.cellText}</span>
      <InfoButtonPopover value={props.infoText}>
        <IonIcon
          style={{ marginLeft: 5, transform: "translateY(2px)" }}
          size="small"
          icon={informationCircleSharp}
          color="primary"
        />
      </InfoButtonPopover>
    </Flex>
  );
}
