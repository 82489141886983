import { IonChip } from "@ionic/react";
import styled from "styled-components";

const StyledChip = styled(IonChip)<{ color: string; background: string }>`
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  text-align: center;
  width: 100%;
  height: 100%;
  // white-space: nowrap;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  // -webkit-box-orient: vertical;
  // overflow: hidden;
  word-wrap: break-word;
  // text-overflow: ellipsis;
`;

type ChipProps = {
  text: string;
  background: string;
  color: string;
};

const BaseChip = (props: ChipProps) => {
  return (
    <div
      style={{
        margin: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <StyledChip mode="md" color={props.color} background={props.background}>
        {props.text}
      </StyledChip>
    </div>
  );
};

export default BaseChip;
