import Card from "./Card";
import PieChart from "./PieChart";
import LineChart from "./LineChart";
import DashboardTable from "../../components/Tables/DashboardTable/DashboardTable";
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonLabel,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import useOrderStatistics from "../../hooks/queries/useOrderStatistics";
import styled from "styled-components";
import "./styles.css";
import Loading from "../../components/Loading/Loading";
import {
  MONTH,
  OrderStatistics,
  Periods,
  TODAY,
  WEEK,
  YEAR,
  useOrderStatisticsContext,
} from "../../context/OrderStatisticsContext";
import PageHeader from "../../components/PageHeader";
import { OrderPerCriteriaItem, OrdersInCountryDTO, OrdersInSalesChannelDTO } from "../../types/orders";

const StyledCard = styled(IonCard)`
  flex: 1;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;

  @media (max-width: 768px) {
    width: auto;
  }
`;

const Container = styled.div<{
  direction: "row" | "column";
}>`
  width: 100%;
  display: flex;

  flex-direction: ${(props) => props.direction};

  @media (max-width: 1380px) {
    flex-direction: column;
    flex-wrap: wrap;
  }

  @media (max-width: 500px) {
    display: block;
  }
`;

const CardsContainer = styled(Container)`
  @media (max-width: 1380px) {
    flex-direction: row;
  }
`;

const CardsFlex = styled.div<{ wrap: string; maxWidth: string }>`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`;

const PieChartWrapper = styled.div`
  display: flex;
  flex: 1;

  @media (max-width: 535px) {
    flex-direction: column;
  }
`;

const Div = styled.div`
  max-width: 400px;
  width: 100%;
  margin-left: 10px;
  margin-bottom: 10px;

  @media (max-width: 400px) {
    margin-left: 0;
  }
`;

const StyledIonSegment = styled(IonSegment)`
  padding: 10px 0;
`;

const StyledIonSegmentButton = styled(IonSegmentButton)<{ active: boolean }>`
  border: ${({ active }) => !active && "1px solid #50c8ff"};

  &::part(indicator-background) {
    background: #4c8dff;
  }
`;

const selectedIonSegmentButton = (period: string, selected: string) =>
  period === selected;

function Dashboard() {
  const { period, setPeriod, orderStatistics } = useOrderStatisticsContext();
  const statistics = useOrderStatistics(period);
  const { t } = useTranslation();

  const showPieChart = orderStatistics.ordersPerCountry.length ? true : false;

  const showOrdersQuantityTable = orderStatistics.ordersWithItemCount.length
    ? true
    : false;

  const wrap = showPieChart || showOrdersQuantityTable ? "wrap" : "no-wrap";

  return (
    <>
      <Loading isLoading={statistics.isLoading} />
      <IonRow className="ion-justify-content-end">
        <IonCol>
          <PageHeader title={t("navigation.dashboard")} />
        </IonCol>
      </IonRow>
      <Container direction={true ? "row" : "column"}>
        <Div>
          <StyledIonSegment
            mode="ios"
            onIonChange={(e) => setPeriod(e.detail.value! as Periods)}
            value={period}
            color={"light"}
          >
            <StyledIonSegmentButton
              value={TODAY}
              active={selectedIonSegmentButton(period, "t")}
            >
              <IonLabel
                color={
                  selectedIonSegmentButton(period, "t") ? "light" : "primary"
                }
              >
                {t("dashboard.today")}
              </IonLabel>
            </StyledIonSegmentButton>
            <StyledIonSegmentButton
              value={WEEK}
              active={selectedIonSegmentButton(period, "w")}
            >
              <IonLabel
                color={
                  selectedIonSegmentButton(period, "w") ? "light" : "primary"
                }
              >
                {t("dashboard.days")}
              </IonLabel>
            </StyledIonSegmentButton>
            <StyledIonSegmentButton
              value={MONTH}
              active={selectedIonSegmentButton(period, "m")}
            >
              <IonLabel
                color={
                  selectedIonSegmentButton(period, "m") ? "light" : "primary"
                }
              >
                {t("dashboard.month")}
              </IonLabel>
            </StyledIonSegmentButton>
            <StyledIonSegmentButton
              value={YEAR}
              active={selectedIonSegmentButton(period, "y")}
            >
              <IonLabel
                color={
                  selectedIonSegmentButton(period, "y") ? "light" : "primary"
                }
              >
                {t("dashboard.year")}
              </IonLabel>
            </StyledIonSegmentButton>
          </StyledIonSegment>
        </Div>
      </Container>
      <CardsContainer direction={true ? "row" : "column"}>
        <CardsFlex wrap={"wrap"} maxWidth={"400px"}>
          <Card
            icon={"orderIcon"}
            title={t("dashboard.total-orders")}
            infoText={t("dashboard.total-orders-info")}
            count={orderStatistics.totalOrders}
            bgColor="#FAFBFC"
            flex={1}
          />

          <Card
            icon={"openOrdersIcon"}
            title={t("dashboard.orders-without-inventory")}
            infoText={t("dashboard.orders-without-inventory-info")}
            count={orderStatistics.ordersWithoutInventory}
            bgColor="#FAFBFC"
            flex={1}
          />
        </CardsFlex>

        <CardsFlex wrap={"wrap"} maxWidth={"400px"}>
          <Card
            icon={"inventoryIcon"}
            title={t("dashboard.avg-number")}
            infoText={t("dashboard.avg-number-info")}
            count={orderStatistics.avgNoOfArticlesPerOrder.toFixed(2)}
            bgColor="#FAFBFC"
            flex={1}
          />

          <Card
            icon={"averageIcon"}
            title={t("dashboard.processed-orders")}
            infoText={t("dashboard.processed-orders-info")}
            count={orderStatistics.processedOrders}
            bgColor="#FAFBFC"
            flex={1}
          />
        </CardsFlex>
      </CardsContainer>
      <Container direction={true ? "row" : "column"}>
        <PieChartWrapper>
          {true ? (
            <PieChart
             ordersPerCriteria={mapOrdersInCountryToOrderPerCriteria(orderStatistics.ordersPerCountry)}
              title={t("dashboard.pie-chart-title")}
              infoText={t("dashboard.pie-chart-info")}
            />
          ) : null}
          {true ? (
            <PieChart
              ordersPerCriteria={mapOrdersInSalesChannelToOrderPerCriteria(orderStatistics.ordersPerSalesChannel)}
              title={t("dashboard.pie-chart-title-sales-channel")}
              infoText={t("dashboard.pie-chart-info-sales-channel")}
            />
          ) : null}
        </PieChartWrapper>
        {true ? (
          <StyledCard
            mode="md"
            style={{
              background: "white",
              position: "relative",
            }}
          >
            <DashboardTable
              data={orderStatistics.ordersWithItemCount?.slice(0, 10) ?? []}
            />
          </StyledCard>
        ) : (
          <EmptyOrdersCard message="There are no processed orders" />
        )}
      </Container>

      <div>
        <LineChart
          orderReports={orderStatistics.orderReports ?? []}
          title={t("dashboard.order-reports")}
        />
      </div>
    </>
  );
}

export default Dashboard;

function mapOrdersInSalesChannelToOrderPerCriteria(
  ordersPerSalesChannel: OrdersInSalesChannelDTO[]
): OrderPerCriteriaItem[] {
  return ordersPerSalesChannel.map(order => {
    return {
      critera: order.salesChannel,
      orderCount: order.orderCount
    }
  })
}

function mapOrdersInCountryToOrderPerCriteria(
  ordersPerCountry: OrdersInCountryDTO[]
): OrderPerCriteriaItem[] {
  return ordersPerCountry.map(order => {
    return {
      critera: order.country,
      orderCount: order.orderCount
    }
  })
}

function EmptyOrdersCard({ message }: { message: string }) {
  return null;
  return (
    <IonCard
      mode="md"
      style={{
        background: "white",
        position: "relative",
      }}
    >
      <IonCardContent>{message}</IonCardContent>
    </IonCard>
  );
}
