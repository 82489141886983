import { IonButton, IonIcon, IonPopover } from "@ionic/react";
import { Inventory } from "../../types/inventory";
import { utils, writeFile } from "xlsx";
import { chevronDownOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

const getCustomInventoryData = (inventories: Inventory[]) => {
  return inventories.map((inventory) => ({
    'Id': inventory.id,
    'Article Number': inventory.articleNumber,
    'Product Id': inventory.productId,
    'Description': inventory.description,
    'EAN': inventory.ean,
    'EANic': inventory.eanic,
    'EANoc': inventory.eanoc,
    'Quantity': inventory.quantity,
    'Storage Location': inventory.storageLocation,
  }));
};

export const ExportInventoryToExcel = ({
  inventories,
}: {
  inventories: Inventory[];
}) => {
  const { t } = useTranslation();
  const downloadInventory = t("table.inventory.download-inventory");
  const exportInventoriesToExtension = (
    inventories: Inventory[],
    fileExtension: string,
  ) => {
    const customInventories = getCustomInventoryData(inventories) ?? [];
    const wb = utils.book_new();
    let ws = utils.json_to_sheet(customInventories);
    utils.book_append_sheet(wb, ws, "Inventories");
    writeFile(wb, "Inventories" + fileExtension);
  };

  return (
    <>
      <IonButton fill="outline" id="exportInventories">
        {downloadInventory}
        <IonIcon slot="end" icon={chevronDownOutline}></IonIcon>
      </IonButton>
      <IonPopover
        trigger="exportInventories"
        showBackdrop={false}
        triggerAction="click"
        className="ion-padding"
        size="cover"
      >
        <IonButton
          fill="clear"
          expand="full"
          size="small"
          onClick={() => {
            exportInventoriesToExtension(inventories, ".xlsx");
          }}
        >
          Excel
        </IonButton>
        <IonButton
          fill="clear"
          expand="full"
          size="small"
          onClick={() => exportInventoriesToExtension(inventories, ".csv")}
        >
          CSV
        </IonButton>
      </IonPopover>
    </>
  );
};
