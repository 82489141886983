import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonAlert,
  IonItem,
  IonLabel,
  IonIcon,
  IonButton,
} from "@ionic/react";
import { Input } from "../../ui/common/Input";
import { lockClosedOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { RouteComponentProps, useLocation } from "react-router";
import useResetPassword from "../../hooks/mutations/useResetPassword";
import { ResetPasswordData } from "../../types/auth";
import styled from "styled-components";
import useSuccessToast from "../../hooks/toasts/useSuccessToast";
import { validatePassword } from "../../utils/validation";
import useDangerToast from "../../hooks/toasts/useDangerToast";
import { useTranslation } from "react-i18next";
import PasswordRequirements from "../../components/PasswordRequirements/PasswordRequirements";
import TranslationBanners from "../../components/TranslationBanners/TranslationBanners";
import Logo from "../../images/logo.png";

const Button = styled(IonButton)`
  --background: #2196f3;
`;

const StyledIonRow = styled(IonRow)`
  flex-direction: column;
  gap: 30px;
  align-items: center;
`;

const StyledImage = styled.img`
  margin-top: 180px;
`;

const ResetPassword: React.FC<RouteComponentProps> = (props) => {
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [isError, setIserror] = useState<boolean>(false);
  const queryParams: any = new URLSearchParams(props.location.search);
  const preferredLanguage = queryParams.get("preferredLanguage");
  const successToast = useSuccessToast();
  const dangerToast = useDangerToast();
  const resetPassword = useResetPassword();
  const location = useLocation();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const passwordResetSuccess = t("toast.password-reset-success");

  const data = {
    queryString: location.search,
    password: password,
    confirmPassword: confirmPassword,
  } as ResetPasswordData;

  function handleChangeLanguage(language: "en-US" | "de") {
    i18n.changeLanguage(language);
    localStorage.setItem("current-language", language);
  }

  const onSubmit = async () => {
    const { isValid, errorMessage } = await validatePassword(
      password,
      confirmPassword
    );
    if (errorMessage) {
      const title = t("password-requirements.title");
      const errorMsg = t(errorMessage);
      return dangerToast(`${title} ${errorMsg}`, 5000);
    }

    if (isValid) {
      resetPassword.mutate(data, {
        onSuccess() {
          successToast(passwordResetSuccess);
          props.history.push("/login");
        },
      });
    }
  };

  useEffect(() => {
    if (queryParams !== null && queryParams !== undefined && queryParams) {
      if (preferredLanguage === "German") {
        localStorage.setItem("initial-language", "de");
        handleChangeLanguage("de");
      } else {
        localStorage.setItem("initial-language", "en-US");
        handleChangeLanguage("en-US");
      }
    }
  }, []);

  return (
    <IonPage>
      <IonContent className="ion-text-center">
        <div className="ion-padding">
          <TranslationBanners />
        </div>
        <IonGrid className="ion-no-padding">
          <StyledIonRow className="ion-justify-content-center">
            <StyledImage src={Logo} alt="logo" />
            <IonCol size="12" sizeMd="6" sizeXl="4">
              <IonCard>
                <IonCardContent>
                  <IonGrid>
                    <IonRow className="ion-justify-content-center">
                      <IonCol>
                        <IonAlert
                          isOpen={isError}
                          onDidDismiss={() => setIserror(false)}
                          cssClass="my-custom-class"
                          header={"Login Error!"}
                          message={"Something went wrong..."}
                          buttons={["Dismiss"]}
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                      <IonCol size="12" className="ion-margin-bottom">
                        <IonItem fill="outline">
                          <IonLabel position="floating">
                            {t("auth.password")}
                          </IonLabel>
                          <IonIcon slot="start" icon={lockClosedOutline} />
                          <Input
                            type="password"
                            value={password}
                            onIonChange={(e) => setPassword(e.detail.value!)}
                          ></Input>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                      <IonCol size="12" className="ion-margin-bottom">
                        <IonItem fill="outline">
                          <IonLabel position="floating">
                            {t("auth.confirm-password")}
                          </IonLabel>
                          <IonIcon slot="start" icon={lockClosedOutline} />
                          <Input
                            type="password"
                            value={confirmPassword}
                            onIonChange={(e) =>
                              setConfirmPassword(e.detail.value!)
                            }
                          ></Input>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                      <IonCol>
                        <Button expand="block" onClick={onSubmit}>
                          {t("common.submit")}
                        </Button>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <PasswordRequirements
                          password={password ? password : ""}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </StyledIonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default ResetPassword;
