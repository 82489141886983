import React from "react";
import { createRoot } from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { browserHistory } from "./utils/history";
import { AuthProvider } from "./context/AuthContext";
import "./i18";
import { MenuProvider } from "./context/MenuContext";
import RealApp from "./components/RealApp";
import { OrderStatisticsProvider } from "./context/OrderStatisticsContext";

const container = document.getElementById("root");
const root = createRoot(container!);

setupIonicReact();

export const appQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    },
  },
});
root.render(
  <React.StrictMode>
    <IonReactRouter history={browserHistory}>
      <AuthProvider removeQueries={() => appQueryClient.removeQueries()}>
        <QueryClientProvider client={appQueryClient}>
          <OrderStatisticsProvider>
            <MenuProvider>
              <RealApp />
            </MenuProvider>
          </OrderStatisticsProvider>
        </QueryClientProvider>
      </AuthProvider>
    </IonReactRouter>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
