import { IonText } from "@ionic/react";
import DataTable, { TableProps } from "react-data-table-component";
// import { useTranslation } from "react-i18next";

function BaseTable<T>(props: TableProps<T>): JSX.Element {
  // const { t } = useTranslation();
  return (
    <DataTable
      pagination
      fixedHeader={true}
      noDataComponent={
        <IonText color="medium">
          {/* <p className="poppins">{t("table.no-records")}</p> */}
        </IonText>
      }
      {...props}
    />
  );
}

export default BaseTable;
