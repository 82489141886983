import { Orders } from "../../api";
import { useQuery } from "@tanstack/react-query";
import { useAuthContext } from "../../context/AuthContext";

export default function useOrderStatistics(period: string) {
  const { isCurrentUserEmployee } = useAuthContext();
  return useQuery(
    ["order-statistics", period],
    async () => {
      return await Orders.getStatistics(period);
    },
    { enabled: isCurrentUserEmployee },
  );
}
