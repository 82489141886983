import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Users } from "../../api";
import { UserAddInputDTO } from "../../types/users";

export default function useCreateUser() {
  const queryClient = useQueryClient();
  return useMutation((user: UserAddInputDTO) => Users.createUser(user), {
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
    },
  });
}
