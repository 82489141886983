import { useState, PropsWithChildren, Fragment, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonSplitPane,
  IonToolbar,
} from "@ionic/react";
import styled from "styled-components";
import logo from "../../images/logo.png";
import { useHistory, useLocation } from "react-router";
import { NavItems } from "../../displays/navigation-items";
import { useAuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import Avatar from "../../components/Avatar";
import TranslationBanners from "../../components/TranslationBanners/TranslationBanners";
import SignOutIcon from "../../icons/sign-out-icon.svg";

const Space = styled.div`
  width: 40px;
`;

const StyledMenu = styled(IonMenu)`
  max-width: 280px;
  border-color: #edf0f7;
`;

const StyledItem = styled(IonItem)<{ active: boolean }>`
  --background: ${(props) =>
    props.active ? "rgba(21, 137, 173, 0.1)" : "transparent"};
  --color: ${(props) => (props.active ? "#4c8dff" : "black")};
  font-weight: bold;
`;

const StyledList = styled(IonList)`
  background: transparent;
  display: flex;
  flex-direction: column;
  height: 90%;
`;

const StyledToolbar = styled(IonToolbar)`
  --background: #fafbfc;
  --color: white;
  --min-height: 80px;
  --padding-top: 20px;
`;

const StyledContent = styled(IonContent)`
  --background: #fafbfc;
  --color: white;
  --min-height: 80px;
  --padding-top: 20px;
  --padding-bottom: 20px;
`;

const Divider = styled.div`
  background: #fafbfc;
  height: 1px;
  width: 80%;
  margin: 0 auto;
  margin-top: 40px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

type DesktopProps = {
  navItems: NavItems[];
  defaultSelectedNavItem: string;
};

function Desktop(props: PropsWithChildren<DesktopProps>) {
  const { logout } = useAuthContext();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [currentLocation, setCurrentLocation] = useState(
    () => props.defaultSelectedNavItem
  );

  const handleClick = (path: string) => {
    setCurrentLocation(path);
    history.push(`/${path}`);
  };

  useEffect(() => {
    if (location.pathname !== "login") {
      setCurrentLocation(location.pathname.split("/")[1]);
    }
  }, [location]);

  return (
    <IonSplitPane when="lg" contentId="main">
      <StyledMenu contentId="main">
        <IonHeader className="ion-no-border">
          <StyledToolbar>
            <Flex>
              <img alt="Vonexio logo" src={logo} />
            </Flex>
            <Divider />
          </StyledToolbar>
        </IonHeader>
        <StyledContent>
          <StyledList lines="none" inset={false}>
            <Fragment>
              {props.navItems.map((item) => {
                const Icon = item.icon;

                return (
                  <StyledItem
                    key={item.linkTo}
                    active={currentLocation === item.linkTo}
                    button
                    onClick={(e) => {
                      e.preventDefault();
                      handleClick(item.linkTo);
                    }}
                  >
                    <Icon
                      marginRight="35px"
                      width={30}
                      height={30}
                      active={currentLocation === item.linkTo}
                    />

                    <IonLabel
                      className="ion-float-left"
                      style={{ padding: "20px 0" }}
                    >
                      {t(`navigation.${item.linkTo}`)}
                    </IonLabel>
                  </StyledItem>
                );
              })}
            </Fragment>
            <StyledItem active={false} button onClick={() => logout()}>
              <IonLabel className="ion-float-left">
                {t("navigation.logout")}
              </IonLabel>
              <IonIcon
                color="#fff"
                slot="start"
                size="large"
                src={SignOutIcon}
              />
            </StyledItem>
          </StyledList>
        </StyledContent>
      </StyledMenu>

      <div className="ion-page" id="main">
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <TranslationBanners />
              <Space />
              <Avatar />
            </div>
          </IonToolbar>
        </IonHeader>
        <IonContent style={{ position: "relative" }}>
          <>{props.children}</>
        </IonContent>
      </div>
    </IonSplitPane>
  );
}
export default Desktop;
