import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Users } from "../../api";
import { ActivateUserData } from "../../types/auth";

// export default function useActivateUser({
//   token,
//   email,
// }: {
//   token: string;
//   email: string;
// }) {
//   const queryClient = useQueryClient();
//   return useQuery(
//     ["activate"],
//     async () => {
//       return await Users.activateUser({ token, email });
//     },
//     {
//       staleTime: Infinity,
//     },
//   );
// }

export default function useActivateUser() {
    return useMutation((data: ActivateUserData) => Users.activateUser(data), {
      onSuccess: () => {},
    });
  }
