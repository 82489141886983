export default function UnlockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
    >
      <path
        d="M18.9891 7.17146C18.3206 4.96062 16.2532 3.36462 13.8289 3.39496C10.9507 3.42996 8.62322 5.74812 8.57422 8.62629V11.1568"
        stroke="#6E7191"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8946 16.7013V19.2924"
        stroke="#6E7191"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8947 10.4806C7.19217 10.4806 4.95801 12.3099 4.95801 17.7968C4.95801 23.2848 7.19217 25.1141 13.8947 25.1141C20.5983 25.1141 22.8325 23.2848 22.8325 17.7968C22.8325 12.3099 20.5983 10.4806 13.8947 10.4806Z"
        stroke="#6E7191"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
