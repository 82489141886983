import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { IonCard } from "@ionic/react";
import styled from "styled-components";
import { OrderPerCriteriaItem, OrdersInCountryDTO } from "../../types/orders";
import { DashboardTitle } from "../../components/Tables/DashboardTable/DashboardTable";
import InfoButton from "../../components/Buttons/InfoButton/InfoButton";
import { useTranslation } from "react-i18next";

const StyledCard = styled(IonCard)`
  flex: 1;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;

  @media (max-width: 768px) {
    width: auto;
  }
`;

const Div = styled.div`
  width: 380px;

  @media (max-width: 1380px) {
    width: 100%;
  }
`;

type PieChartProps = {
  ordersPerCriteria: OrderPerCriteriaItem[];
  title: string;
  infoText: string;
};

type CountryData = {
  country: string;
  value: number;
};

type CleanedData = {
  country: string;
  percentage: number;
};

function cleanupData(data: CountryData[]): CleanedData[] {
  const total = data.reduce((a, b) => a + b.value, 0);
  let cleanedData = data
    .map(({ country, value }) => {
      const percentage = (value / total) * 100;
      return percentage >= 0.1 ? { country, percentage } : null;
    })
    .filter((item): item is CleanedData => item !== null);

  // Calculate the sum of the cleaned data
  const cleanedTotal = cleanedData.reduce((a, b) => a + b.percentage, 0);

  // If there's only one element, return [{ country: <country>, percentage: 100 }]
  if (cleanedData.length === 1) {
    return [{ country: cleanedData[0].country, percentage: 100 }];
  } else {
    return cleanedData.map(({ country, percentage }) => ({
      country,
      percentage: (percentage / cleanedTotal) * 100,
    }));
  }
}

const PieChart = (props: PieChartProps) => {
  const { t } = useTranslation();
  const countryData: CountryData[] = [];

  if (props.ordersPerCriteria != null) {
    props.ordersPerCriteria.forEach((order) => {
      if (
        order.orderCount != null &&
        order.orderCount !== 0 &&
        order.critera != null
      ) {
        countryData.push({ country: order.critera, value: order.orderCount });
      }
    });
  }
  const pieChartData = cleanupData(countryData);

  const options: ApexOptions = {
    labels: pieChartData.map((d) => d.country),
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
    },
  };

  return (
    <StyledCard mode="md">
      <InfoButton infoText={props.infoText} />
      <DashboardTitle marginLeft={pieChartData.length ? "0px" : "16px"}>
        {pieChartData.length
          ? props.title
          : t("dashboard.pie-chart-missing-data")}
      </DashboardTitle>
      <Div>
        <Chart
          options={options}
          series={pieChartData.map((d) => d.percentage)}
          type="pie"
        />
      </Div>
    </StyledCard>
  );
};

export default PieChart;
