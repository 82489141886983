import { IonPopover, IonContent } from "@ionic/react";
import { PropsWithChildren, ReactNode, useRef, useState } from "react";
import styled from "styled-components";
import "./popover-styles.css";

const StyledContent = styled(IonContent)`
  width: 400px;
  @media (max-width: 400px) {
    width: 100%;
  }
`;

type PopoverProps = PropsWithChildren & {
  value?: string | ReactNode;
  infoText?: boolean;
};

export default function Popover(props: PopoverProps) {
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const openPopover = (e: any) => {
    popover.current!.event = e;
    setPopoverOpen(true);
  };

  return (
    <>
      <div
        style={{
          transform: props.infoText ? "translate(-5px, 5px)" : "translate(0,0)",
          cursor: "pointer",
        }}
        onClick={openPopover}
      >
        {props.children}
      </div>
      <IonPopover
        ref={popover}
        isOpen={popoverOpen}
        onDidDismiss={() => setPopoverOpen(false)}
        showBackdrop={false}
      >
        <StyledContent className="ion-padding">{props.value}</StyledContent>
      </IonPopover>
    </>
  );
}
