import { useIonToast } from "@ionic/react";

const usePrimaryToast = () => {
  const [presentToast] = useIonToast();

  return (message?: string, duration?: number) => {
    return presentToast({
      message: message ?? "Success!",
      duration: duration ?? 3000,
      position: "top",
      color: "primary",
    });
  };
};
export default usePrimaryToast;
