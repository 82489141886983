import { Orders } from "../../api";
import { useQuery } from "@tanstack/react-query";

export default function useOrders() {
  return useQuery(
    ["orders"],
    async () => {
      return await Orders.getOrders();
    },    
  );
}
