import { IonCard, IonCol, IonGrid, IonRow } from "@ionic/react";
import { useTranslation } from "react-i18next";
import useOrders from "../../hooks/queries/useOrders";
import PageHeader from "../../components/PageHeader";
import OrderTable from "../../components/Tables/OrderTable/OrdersTable";
import Loading from "../../components/Loading";
import { OrderOutputDTO } from "../../types/orders";

export default function OrderOverview() {
  const orders = useOrders();
  const { t } = useTranslation();
  const title = t(`navigation.order-overview`);
  let sortedOrders: OrderOutputDTO[] = [];
  if (orders?.data != null) {
    sortedOrders = [...orders.data].sort(
      (a: unknown, b: unknown) =>
        new Date((b as OrderOutputDTO).importDate).getTime() -
        new Date((a as OrderOutputDTO).importDate).getTime(),
    );
  }
  const delivered = t("table.order.orderStatuses.delivered");
  const awaiting = t("table.order.orderStatuses.awaiting");
  const canceled = t("table.order.orderStatuses.canceled");

  const STATUSES = {
    fulfilled: delivered,
    "awaiting fulfillment": awaiting,
    deleted: canceled,
  };

  const transformedOrders = sortedOrders.map((order) => ({
    ...order,
    orderStatus: STATUSES[order.orderStatus as keyof typeof STATUSES],
  }));

  return (
    <>
      <Loading isLoading={orders.isLoading} />
      <IonGrid>
        <IonRow className="ion-justify-content-end">
          <IonCol>
            <PageHeader title={title} />
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonCard>
        <OrderTable data={transformedOrders} />
      </IonCard>
    </>
  );
}
