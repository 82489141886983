import useUsers from "../../hooks/queries/useUsers";
import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  useIonModal,
} from "@ionic/react";
import UsersTable from "../../components/Tables/UsersTable/UsersTable";
import { addOutline } from "ionicons/icons";
import useCompanies from "../../hooks/queries/useCompanies";
import useSyncCompanies from "../../hooks/queries/useSyncCompanies";
import { useTranslation } from "react-i18next";
import PageHeader from "../../components/PageHeader";
import CreateOrEditUserModalContent, {
  DataDismiss,
  TitleEnum,
} from "../../components/ModalsContents/CreateOrEditUser/CreateOrEditUser";

import styled from "styled-components";
import Loading from "../../components/Loading";

export const Card = styled(IonCard)`
  @media (max-width: 400px) {
    margin: 0;
  }
`;

export default function UsersPage() {
  const { t } = useTranslation();
  const createUser = t(`users-page.title.create`);
  const users = useUsers();
  useSyncCompanies();
  const companies = useCompanies();

  const title = t(`table.user.title`);

  const [present, dismiss] = useIonModal(CreateOrEditUserModalContent, {
    onDismiss: (data: DataDismiss, role: string) => dismiss(data, role),
    title: TitleEnum.CreateUser,
    companies: companies.data,
  });

  return (
    <>
      <Loading isLoading={users.isLoading} />
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <PageHeader title={title} />
            <IonButton
              style={{ marginLeft: "auto" }}
              onClick={() => present()}
              className="ion-text-capitalize ion-float-right"
            >
              <IonIcon slot="start" icon={addOutline} />
              {createUser}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
      <Card mode="md">
        <UsersTable data={users.data ?? []} />
      </Card>
    </>
  );
}
