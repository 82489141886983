import React from "react";
import { IonCard, IonCardContent, IonSpinner } from "@ionic/react";
import styled from "styled-components";

const AbsolutePosition = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 10000000;
`;
const Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10000000;
  background: rgba(0, 0, 0, 0.2);
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Space = styled.div`
  width: 20px;
`;

const CustomLoading: React.FC = () => {
  return (
    <>
      <Backdrop />
      <AbsolutePosition>
        <IonCard>
          <IonCardContent>
            <Flex>
              <IonSpinner name="circles" />
              <Space />
              <div slot="end">Loading...</div>
            </Flex>
          </IonCardContent>
        </IonCard>
      </AbsolutePosition>
    </>
  );
};

export default CustomLoading;
