import { BlobInfo } from "./blob-info";

export function downloadPdf(
  filePath: string,
  toast: (
    message?: string | undefined,
    duration?: number | undefined,
  ) => Promise<void>,
  errorMessage: string,
  getBlob: (fileName: string) => Promise<BlobInfo>,
) {
  return async () => {
    let filePathParts = filePath.split("\\");
    if (filePathParts.length === 0) {
      toast(errorMessage, 2000);
      return;
    }

    let fileName = filePathParts[filePathParts.length - 1];
    const pdfBlobInfo = await getBlob(fileName);
    if (!pdfBlobInfo || pdfBlobInfo.contentLength == 0) {
      toast(errorMessage, 2000);
      return;
    }

    let url = URL.createObjectURL(pdfBlobInfo?.file);
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
}
