import styled from "styled-components";
// for table
export const CellWithLongerContentContainer = styled.div<{ width?: string }>`
  width: ${(props) => (props.width ? props.width : "350px")};
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
`;
