import {
  QueryClient,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { Users } from "../../api";
import { UserWithId } from "../../types/users";

export default function useUpdateUser() {
  const queryClient = useQueryClient();
  return useMutation((user: UserWithId) => Users.updateUser(user), {
    onSuccess: () => {
      queryClient.invalidateQueries(["users"]);
    },
  });
}
