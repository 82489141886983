import { COLORS } from "../../../constants/colors";
import BaseChip from "../BaseChip/BaseChip";

type SuccessChipProps = {
  text: string;
};

export default function SuccessChip(props: SuccessChipProps) {
  return (
    <BaseChip
      color={COLORS.green.dark}
      background={COLORS.green.light}
      text={props.text}
    />
  );
}
