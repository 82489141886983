import { Delivery } from "../../../types/delivery";
import { TableColumn } from "react-data-table-component";
import BaseTable from "../BaseTable";
import BaseTableHead from "../BaseTable/BaseTableHead";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { getSearchResult } from "../../../utils/filter";
import { formatDate } from "../../../utils/date";
import useDangerToast from "../../../hooks/toasts/useDangerToast";
import { downloadPdf } from "../../../utils/download-pdf";
import { getNotesBlob } from "../../../utils/fetch-blob";
import CellWithInfo from "../CellWithInfo";

type DeliveryTableProps = {
  data: Delivery[];
};

export default function DelieryTable(props: DeliveryTableProps) {
  const { t } = useTranslation();
  const isEnglishTranslation =
    localStorage.getItem("current-language") === "en-US";
  const title = t("table.delivery.title");
  const labelDeliveryId = t("table.delivery.delivery-note-id");
  const labelDeliveryIdInfo = t("table.delivery.delivery-note-id-info");
  const labelDeliveryDate = t("table.delivery.delivery-date");
  const labelDeliveryDateInfo = t("table.delivery.delivery-date-info");
  const labelOrderId = t("table.delivery.related-order-id");
  const labelOrderIdInfo = t("table.delivery.related-order-id-info");
  const labelFile = t("table.delivery.file");
  const labelFileInfo = t("table.delivery.file-info");
  const toast = useDangerToast();

  const [searchInput, setSearchInput] = useState<string>("");

  const searchResult = getSearchResult<Delivery>(props.data, searchInput);

  const customStyle = {
    headCells: {
      style: {
        fontFamily: "Poppins",
        fontSize: "14px",
        color: "#6E7191",
      },
    },
    cells: {
      style: {
        fontFamily: "Poppins",
        fontSize: "14px",
        color: "#6E7191",
      },
    },
  };

  const columns: TableColumn<Delivery>[] = [
    {
      name: (
        <CellWithInfo
          cellText={labelDeliveryId}
          infoText={labelDeliveryIdInfo}
        />
      ),
      selector: (row) => row.deliveryNoteId ?? "",
      sortable: false,
      grow: 2,
      minWidth: "180px",
    },
    {
      name: (
        <CellWithInfo
          cellText={labelDeliveryDate}
          infoText={labelDeliveryDateInfo}
        />
      ),
      selector: (row) => row.deliveryNoteDate ?? "",
      grow: 2,
      minWidth: "180px",
      format: (row) =>
        row.deliveryNoteDate ? formatDate(row.deliveryNoteDate) : null,
      sortable: true,
    },
    {
      name: (
        <CellWithInfo cellText={labelOrderId} infoText={labelOrderIdInfo} />
      ),
      minWidth: isEnglishTranslation ? "180px" : "250px",
      grow: 2,
      selector: (row) => row.orderId,
      sortable: false,
    },
    {
      name: <CellWithInfo cellText={labelFile} infoText={labelFileInfo} />,
      grow: 2,
      minWidth: "180px",
      selector: (row) => row.filename ?? "",
      cell: (row) =>
        row.filename ? (
          <a
            onClick={downloadPdf(
              row.filename,
              toast,
              t("common.pdf-unavailable"),
              getNotesBlob,
            )}
            style={{ cursor: "pointer" }}
            download
          >
            Download PDF
          </a>
        ) : (
          <div></div>
        ),
      sortable: false,
    },
  ];

  return (
    <>
      <BaseTableHead pageTitle={title} setSearchInput={setSearchInput} />
      <BaseTable
        data={searchResult}
        columns={columns}
        customStyles={customStyle}
      />
    </>
  );
}
