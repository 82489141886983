import { COLORS } from "../../../constants/colors";
import BaseChip from "../BaseChip/BaseChip";

type DangerChipProps = {
  text: string;
};

export default function DangerChip(props: DangerChipProps) {
  return (
    <BaseChip
      color={COLORS.red.dark}
      background={COLORS.red.light}
      text={props.text}
    />
  );
}
