import { IonButton, IonIcon, IonPopover } from "@ionic/react";
import { OrderOutputDTO } from "../../types/orders";
import { utils, writeFile } from "xlsx";
import { chevronDownOutline } from "ionicons/icons";

const getCustomOrderData = (orders: OrderOutputDTO[]) => {
  return orders.map((order) => ({
    'Order Number': order.orderNumber,
    'Import Date': order.importDate,
    'Sales Channel': order.salesChannel,
    'Customer Name': order.customerName,
    'Delivery Address': order.deliveryAddress,
    'Delivery Date': order.deliveryDate,
    'Order Status': order.orderStatus,
    'Tracking Number': order.trackingNumber,
    'Items': order.items
      .filter((item) => item !== null)
      .map(
        (item) =>
          `${item.itemNumber} - ${item.itemDescription} - ${item.quantityOrdered}`,
      )
      .join("; "),
  }));
};

export const ExportOrdersToExcel = ({
  orders,
}: {
  orders: OrderOutputDTO[];
}) => {
  const exportOrdersToExtension = (
    orders: OrderOutputDTO[],
    fileExtension: string,
  ) => {
    const customHeadings = getCustomOrderData(orders) ?? [];
    const wb = utils.book_new();
    let ws = utils.json_to_sheet(customHeadings);
    utils.book_append_sheet(wb, ws, "Orders");
    writeFile(wb, "Orders" + fileExtension);
  };

  return (
    <>
      <IonButton fill="outline" id="exportOrders">
        Download Orders
        <IonIcon slot="end" icon={chevronDownOutline}></IonIcon>
      </IonButton>
      <IonPopover
        trigger="exportOrders"
        showBackdrop={false}
        triggerAction="click"
        className="ion-padding"
        size="cover"
      >
        <IonButton
          fill="clear"
          expand="full"
          size="small"
          onClick={() => {
            exportOrdersToExtension(orders, ".xlsx");
          }}
        >
          Excel
        </IonButton>
        <IonButton
          fill="clear"
          expand="full"
          size="small"
          onClick={() => exportOrdersToExtension(orders, ".csv")}
        >
          CSV
        </IonButton>
      </IonPopover>
    </>
  );
};
