export default function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M13.3352 19.5078H19.7122"
        stroke="#6E7191"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0578 4.85889V4.85889C14.7138 3.85089 12.8078 4.12289 11.7998 5.46589C11.7998 5.46589 6.78679 12.1439 5.04779 14.4609C3.30879 16.7789 4.95379 19.6509 4.95379 19.6509C4.95379 19.6509 8.19779 20.3969 9.91179 18.1119C11.6268 15.8279 16.6638 9.11689 16.6638 9.11689C17.6718 7.77389 17.4008 5.86689 16.0578 4.85889Z"
        stroke="#6E7191"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5042 7.2114L15.3682 10.8624"
        stroke="#6E7191"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
