import { IonSearchbar } from "@ionic/react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 400px) {
    flex-direction: column;
  }
`;
const SearchBar = styled(IonSearchbar)`
  max-width: 500px;
  @media (max-width: 400px) {
    margin: 0;
  }
`;

type BaseTableHeadProps = {
  pageTitle: string;
  setSearchInput: (value: string) => void;
};

function BaseTableHead(props: BaseTableHeadProps) {
  const { t } = useTranslation();
  const searchLabel = t("common.search");

  return (
    <>
      <Flex>
        <SearchBar
          placeholder={searchLabel}
          mode="ios"
          color="light"
          onIonChange={(e) => props.setSearchInput(e.detail.value!)}
        ></SearchBar>
      </Flex>
    </>
  );
}

export default BaseTableHead;
