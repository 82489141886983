import {
  IonCard,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
} from "@ionic/react";
import {
  AverageNumberIcon,
  TotalOrderIcon,
  InventoryIcon,
  OpenOrdersIcon,
} from "../../icons";
import styled from "styled-components";
import InfoButton from "../../components/Buttons/InfoButton/InfoButton";

const StyledCard = styled(IonCard)<{ backgroundColor: string; flex?: number }>`
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: center;
  min-width: 200px;
  width: 100%;
  background: ${(props) => props.backgroundColor};
  position: relative;
  border-radius: 20px;

  @media (min-width: 1380px) {
    flex: ${({ flex }) => flex};
  }
`;

const StyledIonCardContent = styled(IonCardContent)`
  display: flex;
  align-items: center;
`;

const CardTitle = styled(IonCardTitle)`
  color: #4e4b66;
  font-size: 20px;
`;

const CardSubtitle = styled(IonCardSubtitle)`
  font-weight: normal;
  color: #4e4b66;
  font-size: 30px;
`;

const IconWrapper = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  min-width: 80px;
  min-height: 80px;
  background-color: ${({ color }) => color};
  margin-right: 10px;
`;

type CardProps = {
  icon: string;
  infoText: string;
  title: string;
  count: number | string;
  bgColor: string;
  flex?: number;
};

const icons = {
  orderIcon: (
    <IconWrapper color="rgba(21, 137, 173, 0.1)">
      <TotalOrderIcon width="55" height="55" />
    </IconWrapper>
  ),
  openOrdersIcon: (
    <IconWrapper color="rgba(92, 112, 228, 0.1)">
      <OpenOrdersIcon width="75" height="75" />
    </IconWrapper>
  ),
  inventoryIcon: (
    <IconWrapper color="rgba(116, 36, 69, 0.1)">
      <InventoryIcon width="55" height="55" />
    </IconWrapper>
  ),
  averageIcon: (
    <IconWrapper color="rgba(11, 171, 82, 0.1)">
      <AverageNumberIcon width="55" height="55" />
    </IconWrapper>
  ),
};

const Card = (props: CardProps) => {
  return (
    <StyledCard
      backgroundColor={props.bgColor}
      className="ion-padding"
      mode="md"
      flex={props.flex}
    >
      <StyledIonCardContent>
        {icons[props.icon as keyof typeof icons]}
        <CardTitle className="ion-card-title">{props.title}</CardTitle>
      </StyledIonCardContent>
      <StyledIonCardContent>
        <CardSubtitle>{props.count}</CardSubtitle>
      </StyledIonCardContent>
      <InfoButton infoText={props.infoText} />
    </StyledCard>
  );
};

export default Card;
