import { Invoice } from "../../../types/invoices";
import { TableColumn } from "react-data-table-component";
import BaseTable from "../BaseTable";
import BaseTableHead from "../BaseTable/BaseTableHead";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { getSearchResult } from "../../../utils/filter";
import { formatDate } from "../../../utils/date";
import useDangerToast from "../../../hooks/toasts/useDangerToast";
import { downloadPdf } from "../../../utils/download-pdf";
import { getInvoiceBlob } from "../../../utils/fetch-blob";
import CellWithInfo from "../CellWithInfo/CellWithInfo";

export default function InvoicesTable(data: any) {
  const { t } = useTranslation();
  const title = t("table.invoices.title");
  const labelInvoiceId = t("table.invoices.invoice-id");
  const labelInvoiceIdInfo = t("table.invoices.invoice-id-info");
  const labelInvoiceDate = t("table.invoices.invoice-date");
  const labelInvoiceDateInfo = t("table.invoices.invoice-date-info");
  const toast = useDangerToast();

  const labelFile = t("table.invoices.file");
  const labelFileInfo = t("table.invoices.file-info");

  const [searchInput, setSearchInput] = useState<string>("");

  const searchResult = getSearchResult<Invoice>(data.data, searchInput);
  const isEnglishTranslation =
    localStorage.getItem("current-language") === "en-US";

  const customStyle = {
    headCells: {
      style: {
        fontFamily: "Poppins",
        fontSize: "14px",
        color: "#6E7191",
      },
    },
    cells: {
      style: {
        fontFamily: "Poppins",
        fontSize: "14px",
        color: "#6E7191",
      },
    },
  };

  const columns: TableColumn<Invoice>[] = [
    {
      name: (
        <CellWithInfo cellText={labelInvoiceId} infoText={labelInvoiceIdInfo} />
      ),
      selector: (row) => row.invoiceId ?? "",
      sortable: false,
      grow: 2,
    },
    {
      name: (
        <CellWithInfo
          cellText={labelInvoiceDate}
          infoText={labelInvoiceDateInfo}
        />
      ),
      selector: (row) => row.invoiceDate ?? "",
      format: (row) => (row.invoiceDate ? formatDate(row.invoiceDate) : null),
      sortable: true,
      grow: 2,
    },
    {
      name: <CellWithInfo cellText={labelFile} infoText={labelFileInfo} />,
      selector: (row) => row.filename ?? "",
      grow: 1,
      cell: (row) =>
        row.filename ? (
          <a
            onClick={downloadPdf(
              row.filename,
              toast,
              t("common.pdf-unavailable"),
              getInvoiceBlob,
            )}
            style={{
              cursor: "pointer",
              whiteSpace: "normal",
              overflowWrap: "break-word",
            }}
            download
          >
            Download PDF
          </a>
        ) : (
          <div></div>
        ),
      sortable: false,
    },
  ];

  return (
    <>
      <BaseTableHead pageTitle={title} setSearchInput={setSearchInput} />
      <BaseTable
        data={searchResult}
        columns={columns}
        customStyles={customStyle}
      />
    </>
  );
}
