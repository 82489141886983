import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import { ActivateUserData, PasswordSetAuthDTO } from "../../types/auth";
import { IonCard, IonCol, IonContent, IonGrid, IonIcon, IonPage, IonRow, IonSpinner } from "@ionic/react";
import { Link } from "react-router-dom";
import { checkmarkCircleSharp } from "ionicons/icons";
import useActivateUser from "../../hooks/queries/useActivateUser";
import TranslationBanners from "../../components/TranslationBanners/TranslationBanners";
import styled from "styled-components";
import Logo from "../../images/logo.png";

const StyledIonRow = styled(IonRow)`
  flex-direction: column;
  gap: 30px;
  align-items: center;
`;

const StyledImage = styled.img`
  margin-top: 180px;
`;

const EmailConfirmation: React.FC<RouteComponentProps> = (props) => {
    const [confirmMail, setConfirmMail] = useState<boolean>(false);
    const [resetPassQuery, setResetPassQuery] = useState<string>();
    //const [isError, setIserror] = useState<boolean>(false);
    const queryParams: any = new URLSearchParams(props.location.search);
    const preferredLanguage = queryParams.get("preferredLanguage");
    const email = queryParams.get("email");
    const token = queryParams.get("token");
    //const location = useLocation();
    const { i18n } = useTranslation();
    const history = useHistory();
    //const { t } = useTranslation();

    const activateUser = useActivateUser();

    const data = {
      token: queryParams.get("token"),
      email: queryParams.get("email"),
    } as ActivateUserData;
  
    function handleChangeLanguage(language: "en-US" | "de") {
      i18n.changeLanguage(language);
      localStorage.setItem("current-language", language);
    }

    // type SetPasswordDto = {
    //     token: string;
    //     email: string;
    // };

    // const confirmEmail = async () => {
    //     try {
    //         console.log('activateUser token:', token);
    //         await Users.activateUser(token);
    //         console.log('activateUser:', email);
           
    //       } catch (error: unknown) {
    //         if (axios.isAxiosError(error)) {
    //             console.log('activateUser failed:', email);
    //         }
    //       }
    //   };

    const onEmailConfirmation = async () => {
        activateUser.mutate(data, {
            onSuccess(setPassword: PasswordSetAuthDTO) {
                
                setConfirmMail(true);
                
                const queryString = `?token=${encodeURIComponent(setPassword.token)}&email=${encodeURIComponent(setPassword.email)}&preferredLanguage=${encodeURIComponent(preferredLanguage)}`;
                
                setResetPassQuery(queryString);
                
                setTimeout(() => {
                    history.push('/reset-password' + queryString);
                }, 3000); // 3000 milliseconds = 3 seconds
            },
        });
    };

    useEffect(() => {

        if (queryParams !== null && queryParams !== undefined && queryParams) {
            if (preferredLanguage === "German") {
                localStorage.setItem("initial-language", "de");
                handleChangeLanguage("de");
            } 
            else {
                localStorage.setItem("initial-language", "en-US");
                handleChangeLanguage("en-US");
            }

            onEmailConfirmation()
        }
    }, []);
  
    return (
    <IonPage>
      <IonContent className="ion-text-center">
        <div className="ion-padding">
          <TranslationBanners />
        </div>
        <IonGrid className="ion-no-padding">
        <StyledImage src={Logo} alt="logo" />
          <StyledIonRow className="ion-justify-content-center">
            <IonCol size="12" sizeMd="6" sizeXl="4">

                {/* {
                    confirmMail ?
                    (
                        <IonCard>
                            <h2>Email Confirmation Page</h2>
                            <p>Your account is successfully activated.</p>
                            <IonIcon color="success" size="large" icon={checkmarkCircleSharp}></IonIcon>
                            <p>If you are not automatically redirected, please click on the following <b><Link to="/reset-password">here</Link></b>.</p>
                        </IonCard>
                    ):
                    (
                        <IonCard>
                            <h2>Email Confirmation Page</h2>
                            <p>Confirming your email</p>
                            <IonSpinner name="dots"></IonSpinner>
                            <p>You will soon be redirected to the page to set the new password.</p>
                        </IonCard>
                    )
                } */}

              <IonCard>
                <h2>Email Confirmation Page</h2>
                {
                    confirmMail ?
                    (
                        <div>
                            <p>Your account is successfully activated.</p>
                            <IonIcon color="success" size="large" icon={checkmarkCircleSharp}></IonIcon>
                            <p>If you are not automatically redirected, please click on the following <b><Link to={`/reset-password${resetPassQuery}`}>here</Link></b>.</p>
                        </div>
                    ):
                    (
                        <div>
                            <p>Confirming your email</p>
                            <IonSpinner name="dots"></IonSpinner>
                            <p>You will soon be redirected to the page to set the new password.</p>
                        </div>
                    )
                }
              </IonCard>
              
            </IonCol>
          </StyledIonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
    );
  };

  export default EmailConfirmation;