import { IonCard, IonCol, IonGrid, IonRow } from "@ionic/react";
import useSyncCompanies from "../../hooks/queries/useSyncCompanies";
import PageHeader from "../../components/PageHeader";
import useCompanies from "../../hooks/queries/useCompanies";
import { TableColumn } from "react-data-table-component";
import BaseTable from "../../components/Tables/BaseTable/BaseTable";
import { customStyle } from "../../components/Tables/UsersTable/UsersTable";
import BaseTableHead from "../../components/Tables/BaseTable/BaseTableHead";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { getSearchResult } from "../../utils/filter";
import { Company } from "../../types/company";
import Loading from "../../components/Loading";
import { Card } from "../Users/Users";

export default function Organizations() {
  const { t } = useTranslation();
  const title = t("table.organization.title");
  const name = t("table.organization.name");
  const address = t("table.organization.address");
  useSyncCompanies();
  const companies = useCompanies();

  const [searchInput, setSearchInput] = useState<string>("");

  const searchResult = getSearchResult<Company>(
    companies.data ?? [],
    searchInput,
  );

  const columns: TableColumn<Company>[] = [
    {
      name: name,
      selector: (row) => row.name,
    },
    {
      name: address,
      selector: (row) => row.address,
    },
  ];

  return (
    <>
      <Loading isLoading={companies.isLoading} />
      {companies && companies.data && companies?.data?.length > 0 && (
        <>
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <PageHeader title={title} />
              </IonCol>
            </IonRow>
          </IonGrid>
          <Card mode="md">
            <BaseTableHead pageTitle={title} setSearchInput={setSearchInput} />
            <BaseTable
              customStyles={customStyle}
              columns={columns}
              data={searchResult}
            />
          </Card>
        </>
      )}
    </>
  );
}
