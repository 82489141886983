import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";

const Unauthorized = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton icon={arrowBack}></IonBackButton>
          </IonButtons>
          <IonTitle>Unauthorized!</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <p>You do not have access to the requested page.</p>
        <IonBackButton icon={arrowBack} text="go Back"></IonBackButton>
      </IonContent>
    </IonPage>
  );
};

export default Unauthorized;
