import { Invoices } from "../../api";
import { useQuery } from "@tanstack/react-query";

export default function useInvoices() {
  return useQuery(
    ["invoices"],
    async () => {
      return await Invoices.getInvoices();
    }
  );
}
